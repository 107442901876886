import React, {useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import GeneralContext from '../../context/GeneralContext'

import './static/css/Producto.css'

const Producto = ({producto}) => {
    const GC = useContext(GeneralContext);
    const { modificarProductoDetalle, idioma} = GC;

    const [imagenActual, modificarImagenActual] = useState(producto.Referencia + "-" +producto.Variantes[0].Color._id + ((producto.Variantes[0].ColorSecundario) ? "-"+producto.Variantes[0].ColorSecundario._id : "") +  ".jpg");
    const [colorInicialState, modificarColorInicialState] = useState(0); 

    
    const color = (variante) => {
        return (variante.ColorSecundario)  ? {"background": "linear-gradient(to right,"+ variante.Color.Hexadecimal+ "  0%,"+ variante.Color.Hexadecimal+ " 50%, "+ variante.ColorSecundario.Hexadecimal+ " 50%, "+ variante.ColorSecundario.Hexadecimal+ " 100%)"} : {"backgroundColor": variante.Color.Hexadecimal}
    }
    const movimientoColor = (movimiento) => {
        if(colorInicialState+4 < producto.Variantes.length && movimiento === 1)
            modificarColorInicialState(colorInicialState + movimiento);
        else if(colorInicialState > 0 && movimiento === -1)
            modificarColorInicialState(colorInicialState-1)
    }

    return ( 
        <div className = "Producto anchoProducto">
            <Link to = {"/DetalleProducto/"+producto._id} onClick = {() => modificarProductoDetalle(producto)}>
                <img src = {"/img/productos/" + imagenActual} className = "imagenProducto" alt = {producto.Referencia}></img>
            </Link>
            <div className = "listaColores">
                {
                    (producto.Variantes.length > 4) ? 
                        <img src = "/img/iconos/flecha-izquierda.png" className = "flechaColor" alt = "Flecha-Izquierda" onClick = {() => movimientoColor(-1)}></img>
                        :
                        null
                }
                {
                    producto.Variantes.map((variante, index) => (
                        (index >= colorInicialState &&  index < colorInicialState+4) ? 
                            <div className = "cuadroColor" 
                                 title = {variante.Color["Color"+idioma] + ((variante.ColorSecundario) ? ("-"+variante.ColorSecundario["Color"+idioma]) : "")}
                                 style = {color(variante)} 
                                 onClick = {() => modificarImagenActual(producto.Referencia + "-" +variante.Color._id +((variante.ColorSecundario) ? "-"+variante.ColorSecundario._id : "") + ".jpg")}></div>
                            :
                            null
                    ))
                }
                {
                    (producto.Variantes.length > 4) ? 
                        <img src = "/img/iconos/flecha-derecha.png" className = "flechaColor" alt = "Flecha-Derecha" onClick = {() => movimientoColor(1)}></img>
                        :
                        null
                }
            </div>
            <Link to = {"/DetalleProducto/"+producto._id} className = "tituloProducto" onClick = {() => modificarProductoDetalle(producto)}>
                <p className = "bebas" style = {{fontSize: "22px"}}>{producto.Referencia}</p>
                <p className = "helveticaBold" style = {{fontSize: "16px"}}>{producto.Nombre}</p>
            </Link>
            
            
        </div>
     );
}
 
export default Producto;