//Importacion de componentes externos.
import React, { useState}  from 'react'
import { Routes, Route } from 'react-router-dom'
import ReactLoading from 'react-loading';

//Componentes internos
import Header from './components/Header/Header'
import Index from './components/Index/Index'
import ListaProductos from './components/ListaProductos/ListaProductos'
import DetalleProducto from './components/ListaProductos/DetalleProducto'
import HerramientaDiseño from './components/HerramientaDiseño/HerramientaDiseño'
import ResumenPedido from './components/Pedido/ResumenPedido'
import Registro from './components/Registro/Registro'
import GeneralState from './context/GeneralState'


import './static/css/general.css'

function App() {
  const [loadingState, modificarLoadingState] =  useState({estado: false, texto: ""})
  return (
    <GeneralState>
      <div className = "Pagina">
            {
              (loadingState.estado) ?
                <div className = "EspacioSpin">
                    <ReactLoading type={"spin"} color={"#ffff"} height={100} width={100}/>
                    <p className = "helveticaBold textoSpin">{loadingState.texto}</p>
                </div>
                :
                null
            }
            <Header loadingState = {loadingState} modificarLoadingState = {modificarLoadingState} /> 
          <Routes>
                <Route path = "/"  element={<Index loadingState = {loadingState}/>} />
                <Route path = "/Productos/:idCategoria/:idSubcategoria"  element={<ListaProductos loadingState = {loadingState} modificarLoadingState = {modificarLoadingState}/>} />
                <Route path = "/DetalleProducto/:idProducto"  element={<DetalleProducto loadingState = {loadingState} modificarLoadingState = {modificarLoadingState} />} />
                <Route path = "/HerramientaDiseño/:idProducto" element={<HerramientaDiseño loadingState = {loadingState} modificarLoadingState = {modificarLoadingState}/>}/>
                <Route  path = "/Registro" element={<Registro />} />
                <Route path = "/Pedido" element={<ResumenPedido loadingState = {loadingState} modificarLoadingState = {modificarLoadingState}/>}/>
          </Routes>
      </div> 
      </GeneralState>
  );
}

export default App;
