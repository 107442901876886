import { 
    MOSTRAR_SUBCATEGORIAS,
    MODIFICAR_CATEGORIAS,
    MODIFICAR_SUBCATEGORIAS,
    MODIFICAR_COLORES,
    MODIFICAR_PRODUCTOS,
    MODIFICAR_PRODUCTO_DETALLE,
    MOSTRAR_BARRA_PRODUCTO,
    MODIFICAR_CARRITO,
    MODIFICAR_IDIOMA,
    MODIFICAR_PANTALLA_GRIS,
    MODIFICAR_USUARIO,
    MODIFICAR_DISEÑOS} from '../types'

const generalReducerApp = (state, action) => {
    switch(action.type) {
        case MOSTRAR_SUBCATEGORIAS:
            return {
                ...state,
                isShown: action.payload
            }
        case MODIFICAR_CATEGORIAS:
            return {
                ...state,
                categoriasState: action.payload
            }
        case MODIFICAR_SUBCATEGORIAS:
            return {
                ...state,
                subcategoriasState: action.payload
            }
        case MODIFICAR_COLORES:
            return {
                ...state,
                coloresState: action.payload
            }
        case MODIFICAR_PRODUCTOS:
            return {
                ...state,
                productosState: action.payload
            }
        case MODIFICAR_PRODUCTO_DETALLE:
            return {
                ...state,
                productoDetalle: action.payload
            }
        case MOSTRAR_BARRA_PRODUCTO:
            return {
                ...state,
                barraBuscar: action.payload
            }
        case MODIFICAR_CARRITO:
            return {
                ...state,
                carrito: action.payload
            }
        case MODIFICAR_IDIOMA:
            return {
                ...state,
                idioma: action.payload
            }
        case MODIFICAR_PANTALLA_GRIS:
            return {
                ...state,
                pantallaGris: action.payload
            }
        case MODIFICAR_USUARIO:
            return {
                ...state,
                usuario: action.payload
            }
        case MODIFICAR_DISEÑOS:
            return {
                ...state,
                diseños: action.payload
            }
        default:  
            return state;
    }
}
export default generalReducerApp;