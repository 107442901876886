import React from 'react'


import './static/css/Registro.css'

const Registro = () => {
    return ( 
        <div className = "espacio-registro">
            <h1>Registro</h1>
        </div>
     );
}
 
export default Registro;