import React , {useContext, useState, useEffect, useRef} from 'react';
import GeneralContext from '../../context/GeneralContext'
import axios from 'axios'
import * as ReactTooltip from 'react-tooltip';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';



import PaypalCheckoutbutton from '../../services/PaypalCheckoutButton'

import { Button, Table, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';


import './static/css/ResumenPedido.css'
import './static/css/Pedido.css'
import './static/css/PedidoMovilGrande.css'
import './static/css/PedidoMovilHorizontal.css'
import './static/css/PedidoMovilPequeño.css'
import './static/css/PedidoTablet.css'
import './static/css/PedidoWeb.css'
const ResumenPedido = ({loadingState, modificarLoadingState}) => {

    const GC = useContext(GeneralContext);
    const { carrito, modificarCarrito , idioma, urlBack, usuario, modificarUsuario } = GC;

    const [paginaState, modificarPaginaState] = useState(1)


    const [totalPedido, modificarTotalPedido] = useState(0);

    const [mostrarError, modificarMostrarError] = useState(false);

    const [verificarState, modificarVerificarState] = useState({estado: false, texto: ""})

    const [listaToggleState, modificarListaToggleState] = useState([])

    const [productoEdicion, setProductoEdicion] = useState(null);

    const [modalVistaEdicion, setModalVistaEdicion] = useState(false);
    const toggleVistaEdicion = () => setModalVistaEdicion(!modalVistaEdicion);

    const [tallasStateEdicion, modificarTallasStateEdicion] = useState(null);

    const [posicionCarritoEd, setPosicionCarritoEd] = useState(null);

    const [colorInicialState, modificarColorInicialState] = useState(0);

    const [mensajeProductoNuevo, setMensajeProductoNuevo] = useState(false);

    const [order, setOrder] = useState(null);

    //Se definen los estilos dinamicos de cada boton de pagina.
    const stylePagina1 = (paginaState === 1) ? {backgroundColor : "black", color: "white"} : {}
    const stylePagina2 = (paginaState === 2) ? {backgroundColor : "black", color: "white"} : {}
    const stylePagina3 = (paginaState === 3) ? {backgroundColor : "black", color: "white"} : {}
    const stylePagina4 = (paginaState === 4) ? {backgroundColor : "black", color: "white"} : {}
    

    const refCodigo1 = useRef(null);
    const refCodigo2 = useRef(null);
    const refCodigo3 = useRef(null);
    const refCodigo4 = useRef(null);
    const refCodigo5 = useRef(null);
    const refCodigo6 = useRef(null);

    //const root = ReactDOM.createRoot(document.getElementById('root'));


    const textos = {
        ES: {
            Producto: "Producto",
            Referencia: "Referencia",
            Cantidad: "Cantidad",
            Diseños: "Diseños",
            CostoUnidad: "Costo Unidad",
            CostoTotal: "Costo Total",
            Costo: "Subtotal conjunto",
            Opciones: "Opciones",
            Imagen: "Imagen",
            TipoDiseño: "Tipo de diseño",
            Colores: "Colores",
            Tamaño: "Tamaño",
            Norealizado: "No realizado",
            NoAñadido: "Nada por aqui aun! Añade un producto",
            Total: "Total",
            ResumenPedido: ["Resumen Pedido", "Datos cliente", "Verificacion Correo", "Metodo pago"],
            Nombre: "Nombre",
            NombrePlaceHolder: "Ingrese su nombre",
            Apellido: "Apellido",
            ApellidoPlaceHolder: "Ingrese su apellido",
            Celular: "Numero de celular",
            CelularPlaceholder: "Ingrese su numero de celular",
            Nacimiento: "Fecha de nacimiento",
            NacimientoPlaceHolder: "Ingrese su fecha de nacimiento",
            Genero: "Genero",
            GeneroSeleccion: "Seleccione su genero",
            Masculino: "Masculino",
            Femenino: "Femenino",
            Pais: "Pais",
            PaisSeleccion: "Seleccione su país",
            ProvinciaCiudad: "Provincia",
            ProvinciaCiudadPlaceholder: "Ingrese su provincia",
            CodigoPostal: "Codigo Postal",
            CodigoPostalSeleccion: "Ingrese su codigo postal",
            Correo: "Correo electronico",
            CorreoSeleccion: "Ingrese su correo electronico",
            Direccion: "Direccion",
            DireccionPlaceHolder: "Ingrese su direccion",
            Barrio: "Ciudad",
            BarrioPlaceHolder: "Ingrese su ciudad",
            Siguiente: "Siguiente",
            Atras: "Atras",
            Campo: "Este campo no puede estar vacío",
            Caracteres1: "Este campo no puede contener letras o caracteres especiales, solo numeros",
            Caracteres2: "Este campo no puede tener menos de 9 digitos",
            NombreValidacion: "Este campo no puede contener caracteres especiales, empezar o terminar con 1 espacio, o tener 2 espacios entre palabras",
            CorreoValidacion1: "El Correo debe contener una @",
            CorreoValidacion2: 'El correo debe contener ".com", ".co" o ".es" detras de la @',
            NacimientoValidacion: 'La fecha ingresada es incorrecta',
            ErrorUsuario: 'Todos los campos deben estar llenos y correctos para continuar',
            FinalizacionCompra: "Finalizacion de compra",
            TextoPago1: "Al realizar realizar el pago mediante Paypal enviaremos un mensaje a tu correo electronico con la informacion que nos proporcionaste y los datos de tu compra. Si tienes alguna inquietud por favor comunicate con nosotros.",
            TexotPago2: "Si tienes un cupón aplicalo aqui:",
            CuponPlaceHolder: "Ingrese su cupon",
            Canjear: "Canjear",
            TextoPago3: "A continuacion te mostramos los diversos tipos de pagos que puedes utilizar, todos estos desde la seguridad de la plataforma de paypal.",
            TextoAyudaCelular1: "Primero escribimos el codigo de la ciudad y despues el numero de telefono.",
            TextoAyudaCelular2: "Por ejemplo, un numero de telefono de la ciudad de Toronto (+416) seria asi: +416 2348123",
            TextoAyudaCelular3: "Pero no hace falta añadir espacios o signos, lo cual quedaria de la siguiente forma: 4162348123",
            consultaUsuario: "Consultando usuario...",
            textoVerificacion1: "Queremos brindarte una experiencia segura y confiable, por eso te pedimos verificar tu registro.",
            textoVerificacion2: "Por favor ingresa el código de seis (6) dígitos que enviamos a tu correo electrónico.",
            textoVerificacion3: "Si no lo encuentras en la bandeja de entrada, revisa en la carpeta de spam o no deseados.",
            Verificar: "Verificar",
            CorreoVerificado: "Tu correo ha sido verificado con exito",
            CodigoIncorrecto: "El codigo que ingresaste es incorrecto",
            Talla: "Tallas",
            EditarCantidades: "Editar cantidades",
            TotalPedido: "Total del pedido",
            ContinuarPedido: "Continuar con el pedido",
            NombrePedido: "Nombre del pedido",
            NombrePedidoPlaceHolder: "Inserte el nombre de su pedido",
            Vistaprevia: "Seleccion de tallas",
            TituloDiseños: "Posiciones seleccionadas",
            AyudaPosicionesSeleccionadas: "Aqui veras un resumen de los diseños y posiciones selecionadas para tu producto, si quieres modificar o añadir diseños o posiciones vuelve a la pantalla anterior",
            TipoArte: "Tipo de arte",
            Actualizar: "Actualizar conjunto",
            InfoActualizar: "Aqui actualizaras las tallas del conjunto ya añadido al carrito",
            IVA: "Iva incluido",
            AyudaTallas: "Aqui podras modificar las cantidades de las tallas seleccionadas",
            AyudaFormulario: "Si tienes problemas para pasar las validaciones del formulario por autocompletar los campos, prueba a llenarlos manualmente.",
            mensajeCargando: "Cargando...",
            mensajeProductoNuevo: "Este producto no esta en el carrito actualmente, al añadirlo se creara uno nuevo",
        },
        EN: {
            Producto: "Product",
            Referencia: "Reference",
            Cantidad: "Quantity",
            Diseños: "Designs",
            CostoUnidad: "Unit cost",
            CostoTotal: "Total cost",
            Costo: "Joint subtotal",
            Opciones: "Options",
            Imagen: "Image",
            TipoDiseño: "Design type",
            Colores: "Colors",
            Tamaño: "Size",
            Norealizado: "Unrealized",
            NoAñadido: "Nothing here yet! add a product",
            Total: "Total",
            ResumenPedido: ["Order Summary", "Customer data", "Email verification", "Payment method"],
            Nombre: "Name",
            NombrePlaceHolder: "Enter your name",
            Apellido: "Last name",
            ApellidoPlaceHolder: "Enter your last name",
            Celular: "Cell phone number",
            CelularPlaceholder: "Enter your cell phone number",
            Nacimiento: "Date of birth",
            NacimientoPlaceHolder: "Enter your date of birth",
            Genero: "Gender",
            GeneroSeleccion: "Select your gender",
            Masculino: "Male",
            Femenino: "Female",
            Pais: "Country",
            PaisSeleccion: "Select your country",
            ProvinciaCiudad: "Province",
            ProvinciaCiudadPlaceholder: "Enter your province",
            CodigoPostal: "Postal Code",
            CodigoPostalSeleccion: "Enter your zip code",
            Correo: "Email",
            CorreoSeleccion: "Enter your email",
            Direccion: "Address",
            DireccionPlaceHolder: "Enter your address",
            Barrio: "City",
            BarrioPlaceHolder: "Enter your city",
            Siguiente: "Next",
            Atras: "Back",
            Campo: "This field can not be blank",
            Caracteres1: "This field cannot contain letters or special characters, only numbers",
            Caracteres2: "This field cannot have less than 9 digits",
            NombreValidacion: "This field cannot contain special characters, start or end with 1 space, or have 2 spaces between words",
            CorreoValidacion1: "The Email must contain an @",
            CorreoValidacion2: 'The email must contain ".com", ".co" or ".es" after the @',
            NacimientoValidacion: 'The date entered is incorrect',
            ErrorUsuario: 'All fields must be filled out and correct to continue',
            FinalizacionCompra: "Finalizing the purchase",
            TextoPago1: "When making the payment through Paypal we will send a message to your email with the information you provided and the details of your purchase. If you have any concerns please contact us.",
            TextoPago2: "If you have a coupon, apply it here:",
            CuponPlaceHolder: "Enter your coupon",
            Canjear: "Exchange",
            TextoPago3: "Below we show you the different types of payments that you can use, all of these from the security of the paypal platform.",
            TextoAyudaCelular1: "First we write the city code and then the phone number.",
            TextoAyudaCelular2: "For example, a phone number in the city of Toronto (+416) would be like this: +416 2348123",
            TextoAyudaCelular3: "But it is not necessary to add spaces or signs, which would be as follows: 4162348123",
            consultaUsuario: "Searching user...",
            textoVerificacion1: "We want to provide you with a safe and reliable experience, which is why we ask you to verify your registration.",
            textoVerificacion2: "Please enter the six (6) digit code that we sent to your email.",
            textoVerificacion3: "If you can't find it in your inbox, check your spam or junk folder.",
            Verificar: "Check",
            CorreoVerificado: "Your email has been verified successfully",
            CodigoIncorrecto: "The code you entered is incorrect",
            Talla: "Sizes",
            EditarCantidades: "Edit amounts",
            TotalPedido: "Order total",
            ContinuarPedido: "Continue with the order",
            NombrePedido: "Order name",
            NombrePedidoPlaceHolder: "Enter the name of your order",
            Vistaprevia: "Selection of sizes",
            TituloDiseños: "Selected positions",
            AyudaPosicionesSeleccionadas: "Here you will see a summary of the designs and positions selected for your product, if you want to modify or add designs or positions return to the previous screen",
            TipoArte: "Type of art",
            Actualizar: "Upgrade set",
            InfoActualizar: "Here you will update the sizes of the set already added to the cart",
            IVA: "VAT included",
            AyudaTallas: "Here you can modify the quantities of the selected sizes",
            AyudaFormulario: "If you're having trouble getting past form validations by auto-completing fields, try filling them in manually.",
            mensajeCargando: "Loading...",
            mensajeProductoNuevo: "This product is not currently in the cart, adding it will create a new one"
        }
    }

    const calculoItems = () =>{
        let items = []
        carrito.forEach((producto) => {
            items.push({
                sku: (producto.Imagen).replace('.jpg', ''),
                name: producto.Producto.Nombre,
                price: (calculoUnitario(producto, true)).toString(),
                quantity: calculoCantidad(producto),
                currency: "CAD"
            })
        })

        return items;
    }

    const calculoCantidadTotal = () =>{
        let totalCantidad = 0;
        carrito.forEach((producto) => {
            totalCantidad += calculoCantidad(producto);
        })

        return totalCantidad;
    }


    const calculoCantidad = (producto) =>{
        let cantidadFinal = 0;
        for(let talla in producto.Tallas)
            cantidadFinal += producto.Tallas[talla];
        return cantidadFinal;
    }

    const movimientoColor = (movimiento) => {
        if(colorInicialState+4 < productoEdicion.Producto.Variantes.length && movimiento === 1)
            modificarColorInicialState(colorInicialState + movimiento);
        else if(colorInicialState > 0 && movimiento === -1)
            modificarColorInicialState(colorInicialState-1)
    }

    const calculoPrecio = (producto, descuento, impuesto) =>{
        let cantTotal = calculoCantidad(producto);
        let totalCosto = (parseFloat(producto.Producto.PrecioMayorista))* cantTotal;
        let totalDiseños = 0;
        let margenError = 1.6;
    

        producto.Diseños.forEach((diseñitos) =>{
            if(diseñitos.DiseñoImagen !== null && producto.Producto.Categoria._id === "60b52a7941c7941a70223104"){ //Camisetas
                if(diseñitos.DiseñoImagen.TipoArte === "Impresion" || diseñitos.DiseñoImagen.TipoArte === "Print"){
                    
                    totalDiseños += 25*diseñitos.DiseñoImagen.Colores.length + 1.25*calculoCantidad(producto);
                    margenError = 1.7;
                }
                else if(diseñitos.DiseñoImagen.TipoArte === "Bordado" || diseñitos.DiseñoImagen.TipoArte === "Embroidery"){
                    
                    totalDiseños += 35 + (calculoCantidad(producto) * 5);
                    margenError = 1.7;
                }
                else if(diseñitos.DiseñoImagen.TipoArte === "Transfer"){
                    
                    totalDiseños += (25*diseñitos.DiseñoImagen.Colores.length + 1.25*calculoCantidad(producto))*1.2
                    margenError = 1.6;
                }
            }
            else if(diseñitos.DiseñoImagen !== null && producto.Producto.Subcategoria._id === "60b5199041cbdc2aac232ea0"){ //Gorras
                
                if(diseñitos.DiseñoImagen.TipoArte === "Bordado" || diseñitos.DiseñoImagen.TipoArte === "Embroidery"){
                    totalDiseños += 35 + (calculoCantidad(producto) * 5);
                    margenError = 1.7;
                }
            }
            else if(diseñitos.DiseñoImagen !== null && producto.Producto.Categoria._id === "60b52a7a41c7941a70223123"){ //Ropa de trabajo
                if(diseñitos.DiseñoImagen.TipoArte === "Impresion" || diseñitos.DiseñoImagen.TipoArte === "Print"){
                    
                    totalDiseños += 25*diseñitos.DiseñoImagen.Colores.length + 1.25*calculoCantidad(producto);
                    margenError = 1.7;
                }
                else if(diseñitos.DiseñoImagen.TipoArte === "Bordado" || diseñitos.DiseñoImagen.TipoArte === "Embroidery"){
                    
                    totalDiseños += 35 + (calculoCantidad(producto) * 5);
                    margenError = 1.6;
                }
                else if(diseñitos.DiseñoImagen.TipoArte === "Transfer" || diseñitos.DiseñoImagen.TipoArte === "Transferencia"){
                    
                    totalDiseños += (25*diseñitos.DiseñoImagen.Colores.length + 1.25*calculoCantidad(producto))
                    margenError = 1.6;
                }
            }
        })
        //console.log(totalCosto, totalDiseños, margenError)
        let totalFinal = (totalCosto + totalDiseños)*margenError;
        if(descuento){
            if(cantTotal > 30){
                if(cantTotal > 60){
                    if(cantTotal > 100){
                        totalFinal = totalFinal - (totalFinal * 0.1);
                    }else{
                        totalFinal = totalFinal - (totalFinal * 0.08);
                    }
                }else{
                    totalFinal = totalFinal - (totalFinal * 0.06);
                }
            }
        }

        if(impuesto)
            totalFinal = totalFinal + ((calcularImpuestos()/calculoCantidadTotal())*cantTotal);
        
        return parseFloat(totalFinal);
    }

    const calculoUnitario = (datos, impuesto) =>{
        let total = calculoPrecio(datos, true, impuesto);
        return parseFloat(total / calculoCantidad(datos));
    }

    const calculoTotalPedido = React.useCallback((carritoT, impuesto) =>{
        let totalPedido = 0;
        carritoT.forEach((producto) => {
            totalPedido += calculoPrecio(producto, true, impuesto);
        })
        return (totalPedido);
    });

    const calcularImpuestos = () =>{
        let descuento = 1;
        if(usuario.Departamento_Ciudad){
            if(usuario.Departamento_Ciudad === "AB Alberta") descuento = 0.05;
            if(usuario.Departamento_Ciudad === "BC British Columbia") descuento = 0.05;
            if(usuario.Departamento_Ciudad === "MB Manitoba") descuento = 0.05;
            if(usuario.Departamento_Ciudad === "NB New Brunswick") descuento = 0.15;
            if(usuario.Departamento_Ciudad === "NF Newfoundland") descuento = 0.15;
            if(usuario.Departamento_Ciudad === "NS Nova Scotia") descuento = 0.15;
            if(usuario.Departamento_Ciudad === "NU Nunavut") descuento = 0.05;
            if(usuario.Departamento_Ciudad === "NT Northwest Territories") descuento = 0.05;
            if(usuario.Departamento_Ciudad === "ON Ontario") descuento = 0.13;
            if(usuario.Departamento_Ciudad === "PE Prince Edward Island") descuento = 0.15;
            if(usuario.Departamento_Ciudad === "QC Quebec") descuento = 0.05;
            if(usuario.Departamento_Ciudad === "SK Saskatchewan") descuento = 0.05;
            if(usuario.Departamento_Ciudad === "YT Yukon") descuento = 0.05;
        }
        if(usuario.CodigoPostal === "V6B1T8")
            return ((totalPedido * descuento) + (totalPedido + (totalPedido * descuento)) * 0.025);
        else
            return (totalPedido * descuento);
    }

    const eliminarElementoCarrito = (index) =>{
        let carritoActual = carrito.splice(0);
        carritoActual.splice(index, 1);
        modificarCarrito(carritoActual);
        modificarTotalPedido(calculoTotalPedido(carritoActual, false));
        sessionStorage.clear();
        sessionStorage.setItem("Carrito", carritoActual);
    }


    const continuarPasos = (paso) =>{
        if(paso === 1 || paso === 2){
            modificarPaginaState(paso)
            modificarMostrarError(false)
        }
        else if(paso === 3){
            if(revisionDatosUsuarios() && paginaState === 2)
                actualizacionCreacionUsuario(usuario)
            else
                modificarMostrarError(true)             
        }
        else if(paso === 4){
            if(usuario.Validacion){
                modificarPaginaState(paso)
            }
            else
                modificarVerificarState({estado: true, texto: textos[idioma].CodigoIncorrecto})
        }
        
    }

    //Se realiza una creacion de codigo de verificacion y se hace un envio de correo con este.
    const envioCodigo = async () =>{
        modificarLoadingState({estado: true, texto: textos[idioma].mensajeCargando})
        await axios.get(urlBack + "Api/Cupon/CreateCupones",{
        }).then(async response=>{
            if(!response.data.datos[0].error){
                await axios.post(urlBack + "Api/Cupon/EnviarCodigo",{
                    "data": {
                        "Correo": usuario.Correo,
                        "Nombre": usuario.Nombres + " " + usuario.Apellidos,
                        "Codigo": response.data.Codigo,
                        "Idioma": idioma
                    }
                }).then(response => {
                    modificarLoadingState({estado: false, texto: ""})
                    return !response.data.error
                }).catch(function(err) {
                    console.log("Hubo un error al enviar el codigo", err)
                    modificarLoadingState({estado: false, texto: ""})
                });
            }   
            else{
                console.log("Hubo un error al enviar el codigo")
                modificarLoadingState({estado: false, texto: ""})
            }
        }).catch(function(err) {
            console.log("Hubo un error al consultar", err)
            modificarLoadingState({estado: false, texto: ""})
        });
    }

    //Se verifica si el codigo ingresado es correcto
    const verificarCodigo = async() => {
        const codigo =  document.getElementById("codigo1").value +
                        document.getElementById("codigo2").value +
                        document.getElementById("codigo3").value +
                        document.getElementById("codigo4").value +
                        document.getElementById("codigo5").value +
                        document.getElementById("codigo6").value 

        modificarLoadingState({estado: true, texto: textos[idioma].mensajeCargando})               
        await axios.get(urlBack + "Api/Cupon/ValidarCupon/" + codigo,{
        }).then(response=>{
            if(!response.data.error){
                if(response.data.existe){
                    modificarUsuario({
                        ...usuario,
                        ["Validacion"] : true
                    })
                    actualizacionCreacionUsuario({
                        ...usuario,
                        ["Validacion"] : true
                    })
                    modificarVerificarState({estado: true, texto: textos[idioma].CorreoVerificado})
                }
                else{
                    modificarVerificarState({estado: true, texto: textos[idioma].CodigoIncorrecto})
                }
                modificarLoadingState({estado: false, texto: ""})
            }

        }).catch(function(err) {
            console.log("Hubo un error al consultar", err)
            modificarLoadingState({estado: false, texto: ""})
        });
    }


    //Revisa que ningun dato del state de cliente este vacio.
    const revisionDatosUsuarios = () => {
        for (const dato in usuario){
            if(usuario[dato] === "" && dato != "Celular") return false;
        }
        return true;
    }

    const revisarFormulario = () =>{
        return (document.getElementById("nombres_input").value === "" ||
                document.getElementById("apellidos_input").value === "" ||
                document.getElementById("celular_input").value === "" ||
                document.getElementById("provincia_ciudad_input").value === "" ||
                document.getElementById("direccion_input").value === "" ||
                document.getElementById("barrio_input").value === "")
    }


    //Retorna el string val ingresada con la primera letra mayuscula.
    const capitalizarPalabras = ( val ) => {
        var minuscula = val.toLowerCase();
        minuscula = minuscula.split(" ");
        var result = ""
        for(var i = 0; i < minuscula.length; i++){
            result += minuscula[i][0].toUpperCase() + minuscula[i].slice(1) + ((i+1 < minuscula.length) ? " " : "")
        }
        return result;  
    } 

    /*
    //Retorna la fecha ingresada cambiandole el formato.
    const cambioFormatoFecha = (fecha) => {
        var partido = fecha.split("-");
        return partido[2]+"-"+partido[0]+"-"+partido[1];
    }*/

    //Retorna true si el string ingresado NO contiene algun numero.
    const todoNumeros = (texto) => {
        var numeros="0123456789";
        for(var i=0; i<texto.length; i++){
           if (numeros.indexOf(texto.charAt(i),0) === -1){
              return true;
           }
        }
        return false;
    }

    //Retorna true si el string ingresado contiene algun caracter especial.
    const tiene_caracter_especial = (texto) =>{
        var letras="abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ ";
        texto = texto.toLowerCase();
        for(var i=0; i<texto.length; i++){
           if (letras.indexOf(texto.charAt(i),0) === -1){
              return true;
           }
        }
        return false;
    }

    //Retorna true si el string ingresado contiene algun caracter especial.
    const validacion_caracteres_especiales_codigo_postal = (texto) =>{
        var letras="0123456789-abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ ";
        texto = texto.toLowerCase();
        for(var i=0; i<texto.length; i++){
           if (letras.indexOf(texto.charAt(i),0) === -1){
              return true;
           }
        }
        return false;
    }

    //Bloquea la escritura de letras.
    const bloqueoLetras = (e) => {
        var elemento= document.getElementById(e.target.id);
        var texto = e.target.value;
        if(todoNumeros(e.key) && e.key !== " "){
            elemento.value = texto.replace(e.key, '');
        }
        validacion(e);
    }

    //Bloquea la escritura de caracteres especiales.
    const bloqueoCaracteresEspeciales = (e) => {
        var elemento= document.getElementById(e.target.id);
        var texto = e.target.value;
        if(tiene_caracter_especial(e.key) && e.key !== " "){
            elemento.value = texto.replace(e.key, '');
        }
        validacion(e);
    }

    //Bloquea la escritura de caracteres especiales, excepto numeros.
    const bloqueoCaracteresEspecialesExceptoNumeros = (e) => {
        var elemento= document.getElementById(e.target.id);
        var texto = e.target.value;
        if(tiene_caracter_especial(e.key) && e.key !== " " && todoNumeros(e.key)){
            elemento.value = texto.replace(e.key, '');
        }
        validacion(e);
    }

    const llenarInfo = (usuarioActual) => {
        //Correo
        document.getElementById("correo_input").value = usuario["Correo"];
        document.getElementById("correo_input").classList.add('correcto');
        document.getElementById("correo_input").setAttribute("title", "");

        //Nombres
        document.getElementById("nombres_input").value = usuario["Nombres"];
        document.getElementById("nombres_input").classList.add('correcto');
        document.getElementById("nombres_input").setAttribute("title", "");

        //Apellidos
        document.getElementById("apellidos_input").value = usuario["Apellidos"];
        document.getElementById("apellidos_input").classList.add('correcto');
        document.getElementById("apellidos_input").setAttribute("title", "");

        //Telefono 
        document.getElementById("celular_input").value = usuario["Celular"];
        document.getElementById("celular_input").classList.add('correcto');
        document.getElementById("celular_input").setAttribute("title", "");


        //Provincia y Ciudad
        document.getElementById("provincia_ciudad_input").value = usuario["Departamento_Ciudad"];
        document.getElementById("provincia_ciudad_input").classList.add('correcto');
        document.getElementById("provincia_ciudad_input").setAttribute("title", "");

        //Direccion
        document.getElementById("direccion_input").value = usuarioActual["Direccion"];
        document.getElementById("direccion_input").classList.add('correcto');
        document.getElementById("direccion_input").setAttribute("title", "");

        //Barrio
        document.getElementById("barrio_input").value = usuarioActual["Barrio"];
        document.getElementById("barrio_input").classList.add('correcto');
        document.getElementById("barrio_input").setAttribute("title", "");

        //Codigo Postal 
        document.getElementById("codigoPostal_input").value = usuarioActual["CodigoPostal"];
        document.getElementById("codigoPostal_input").classList.add('correcto');
        document.getElementById("codigoPostal_input").setAttribute("title", "");
    }

    //consulta a un usuario en la base de datos
    const consultaUsuario = React.useCallback(async (Correo) =>{
        modificarLoadingState({estado: true, texto: textos[idioma].consultaUsuario})
        await axios.get(urlBack + "Api/Usuario/GetInfoUsuario/" + Correo,{
            }).then(response => {
                if(!response.data.error && response.data.existe){
                    modificarUsuario(response.data.data);
                    llenarInfo(response.data.data)
                    modificarLoadingState({estado: false, texto: ""});
                }   
                else{
                    modificarUsuario({
                        ...usuario,
                        ["Correo"] : Correo
                    })
                    modificarLoadingState({estado: false, texto: ""})
                }
            }).catch(function(err) {
                console.log("Hubo un error al consultar", err)
            });
    })

    //Actualiza o crea a un usuario en la base de datos
    const actualizacionCreacionUsuario = React.useCallback(async (usuario) =>{
        modificarLoadingState({estado: true, texto: textos[idioma].mensajeCargando})
        await axios.put(urlBack + "Api/Usuario/UpdateUsuarios" ,{
            "NuevosDatosUsuario": [
                usuario
            ]
            }).then(response => {
                if(!response.data[0].error){
                    if(usuario.Validacion)
                        modificarPaginaState(4)
                    else if(envioCodigo())
                        modificarPaginaState(3)  
                    
                    
                    modificarLoadingState({estado: false, texto: ""});
                }
            }).catch(function(err) {
                console.log("Hubo un error al consultar", err)
                modificarLoadingState({estado: false, texto: ""})
            });
    })


    //Convierte la fecha ingresada a formato mm/aaaa/dd
    function convertDateFormat(string) {
        var primerSplit = string.split("T");
        var info = primerSplit[0].split('-');
        return info[1] + '-' + info[2] + '-' + info[0];
    }
    //Funcion principal: 
    //Detecta que tipo de input se esta rellenando y valida la informacion en este.
    //-Corrige escritura automaticamente y bloquea la escritura incorrecta.
    //- Insertar el logo de correcto o incorrecto.
    //Actualiza el state de Cliente y tambien el sesionStorage.
    const validacion = async (e) => {
        var elemento= document.getElementById(e.target.id);
        if (e.target.value === ""){
            elemento.setAttribute("title", textos[idioma].Campo);
            elemento.classList.add('error');
            elemento.classList.remove('correcto');
            modificarUsuario({
                ...usuario,
                [e.target.name] : ""
            })
        }
        else{
            if(e.target.name === 'Celular'){
                if(todoNumeros(e.target.value)){
                    elemento.setAttribute("title", textos[idioma].Caracteres1);
                    elemento.classList.add('error');
                    elemento.classList.remove('correcto');
                    modificarUsuario({
                        ...usuario,
                        [e.target.name] : ""
                    })
                }
                else if(e.target.name === "Celular" && (e.target.value).length < 9){
                    elemento.setAttribute("title", textos[idioma].Caracteres2);
                    elemento.classList.add('error');
                    elemento.classList.remove('correcto');
                    modificarUsuario({
                        ...usuario,
                        [e.target.name] : ""
                    })
                }
                
                else{
                    elemento.setAttribute("title", "");
                    elemento.classList.remove('error');
                    elemento.classList.add('correcto');
                    
                    modificarUsuario({
                        ...usuario,
                        [e.target.name] : e.target.value
                    })
                }
            }
            else if(e.target.name === 'Nombres' || e.target.name === 'Apellidos'){
                if(tiene_caracter_especial(e.target.value) || (e.target.value).indexOf("  ",0) !== -1 || (e.target.value)[0] === " "){
                    elemento.setAttribute("title", textos[idioma].NombreValidacion);
                    elemento.classList.add('error');
                    elemento.classList.remove('correcto');
                    modificarUsuario({
                        ...usuario,
                        [e.target.name] : ""
                    })
                }else{
                    elemento.classList.remove('error');
                    elemento.classList.add('correcto');
                    elemento.setAttribute("title", "");
                    modificarUsuario({
                        ...usuario,
                        [e.target.name] : e.target.value
                    })
                }
            }
            else if(e.target.name === 'Correo'){
                if (e.target.value.indexOf("@") < 0) {
                    elemento.setAttribute("title", textos[idioma].CorreoValidacion1);
                    elemento.classList.add('error');
                    elemento.classList.remove('correcto');
                    modificarUsuario({
                        ...usuario,
                        [e.target.name] : ""
                    })
                }else if (e.target.value.indexOf(".com", e.target.value.indexOf("@")) < 0 && e.target.value.indexOf(".cc", e.target.value.indexOf("@")) < 0 && e.target.value.indexOf(".co", e.target.value.indexOf("@")) < 0 && e.target.value.indexOf(".es", e.target.value.indexOf("@")) < 0) {
                    elemento.setAttribute("title", textos[idioma].CorreoValidacion2);
                    elemento.classList.add('error');
                    elemento.classList.remove('correcto');
                    modificarUsuario({
                        ...usuario,
                        [e.target.name] : ""
                    })
                } 
                else{
                    elemento.classList.remove('error');
                    elemento.classList.add('correcto');
                    elemento.setAttribute("title", "");
                    
                    await consultaUsuario(e.target.value);
                }
            } 
            else if(e.target.name === 'CodigoPostal'){
                if(validacion_caracteres_especiales_codigo_postal(e.target.value) || (e.target.value).indexOf("  ",0) !== -1 || (e.target.value)[0] === " "){
                    elemento.setAttribute("title", textos[idioma].NombreValidacion);
                    elemento.classList.add('error');
                    elemento.classList.remove('correcto');
                    modificarUsuario({
                        ...usuario,
                        [e.target.name] : ""
                    })
                }else{
                    elemento.classList.remove('error');
                    elemento.classList.add('correcto');
                    elemento.setAttribute("title", "");
                    modificarUsuario({
                        ...usuario,
                        [e.target.name] : e.target.value
                    })
                }
            } 
            else{
                elemento.classList.remove('error');
                elemento.classList.add('correcto');
                elemento.setAttribute("title", "");
                
                modificarUsuario({
                    ...usuario,
                    [e.target.name] : e.target.value
                })
                
            }
        }
    }
    
    

    const cambiarToggle = (index) =>{
        let listaT = listaToggleState.slice()
        listaT[index] = !listaT[index]

        modificarListaToggleState(listaT)
    }

    const stringDeTallas = (arrTallas) =>{
        let stringFinal = "";
        for(let talla in arrTallas){
            
            if(arrTallas[talla] > 0){
                stringFinal += " " + talla + " (" + arrTallas[talla] + ")";
            }
        }

        return stringFinal;
    }

    const editarTallas = (prod, pos) => {
        console.log(prod)
        setMensajeProductoNuevo(false);
        setProductoEdicion(prod);
        modificarTallasStateEdicion(prod.Tallas);
        setPosicionCarritoEd(pos)
        toggleVistaEdicion()
        
    }

    const color = (variante) => {
        return (variante.ColorSecundario)  ? {"background": "linear-gradient(to right,"+ variante.Color.Hexadecimal+ "  0%,"+ variante.Color.Hexadecimal+ " 50%, "+ variante.ColorSecundario.Hexadecimal+ " 50%, "+ variante.ColorSecundario.Hexadecimal+ " 100%)"} : {"backgroundColor": variante.Color.Hexadecimal}
    }

    const cambioColor = (variante) => {
        const referencia = (productoEdicion.Imagen).split("-")[0]
        const imagenVariante = referencia + "-" + variante.Color._id + (variante.ColorSecundario ?  "-" + variante.ColorSecundario._id : "") + ".jpg"
        const revisionVariante = carrito.find(productoCarrito => productoCarrito.Imagen === imagenVariante)
        if(revisionVariante){
            const pos = carrito.findIndex(productoCarrito => productoCarrito.Imagen === imagenVariante)
            setMensajeProductoNuevo(false);
            setProductoEdicion(revisionVariante);
            modificarTallasStateEdicion(revisionVariante.Tallas);
            setPosicionCarritoEd(pos)
        }else{
            let productoNuevo = JSON.parse(JSON.stringify(productoEdicion));
            productoNuevo.Imagen = imagenVariante;

            let tallas = productoNuevo.Tallas;
            for(let talla in tallas){
                tallas[talla] = 0;
            }
            productoNuevo.Tallas = tallas;
            setMensajeProductoNuevo(true);
            setProductoEdicion(productoNuevo);
            modificarTallasStateEdicion(productoNuevo.Tallas);
            
        }
        
    }

    const edicionTallasProducto = () =>{
        var carrNew = carrito.slice(0);
        if(mensajeProductoNuevo){
            let productonuevo = JSON.parse(JSON.stringify(productoEdicion));
            productonuevo.Tallas = tallasStateEdicion;
            carrNew.push(productonuevo);
        }
        else
            carrNew[posicionCarritoEd].Tallas = tallasStateEdicion;

        console.log(carrNew)
        modificarCarrito(carrNew);
        modificarTotalPedido(calculoTotalPedido(carrNew, false));
        sessionStorage.clear();
        sessionStorage.setItem("Carrito", carrNew);
        toggleVistaEdicion()
    }
/*
    function tick() {
        const element = (
            <p className = "helveticaBold textoPago">{new Date().toLocaleTimeString()}</p>
        );
        root.render(element);
      }
*/
    useEffect(()=>{
        if(carrito.length !== 0 && totalPedido === 0){
            const total = calculoTotalPedido(carrito, false);
            modificarTotalPedido(total);
        }
        if(paginaState === 2){
            if(revisionDatosUsuarios() && revisarFormulario()){
                llenarInfo(usuario)
            }
        }
        if(carrito.length !== 0 && listaToggleState.length === 0){
            let listaT = [];
            for(let i = 0; i < carrito.length; i++){
                listaT.push(false);
            }
            modificarListaToggleState(listaT)
        }

    }, [carrito.length, carrito, totalPedido, calculoTotalPedido])

    //setInterval(tick, 1000);

    return ( 
        <div className = "ResumenPedido">
            {
                (paginaState === 1) ?
                    <div className = "menuResumen">
                        <div className = "listaResumen">
                            <p className = "bebas tituloResumen" >{textos[idioma].ResumenPedido[paginaState-1]}</p>
                            <div style = {{overflowY: "auto", overflowX: "hidden", width: "50vw"}}>
                                {
                                    (carrito.length > 0) ?
                                        carrito.map((producto, index) =>(
                                            <div className = "productoCuadro">
                                                <div className = "FlexDirection-row AlignItems-center JustifyContent-spaceBetween">
                                                    <p className = "helveticaBold referenciaProducto ">{producto.Producto.Nombre}</p>
                                                    <img src = "/img/iconos/eliminar.png" className = "imagenEliminar" onClick = {() => eliminarElementoCarrito(index)} alt = "Delete"></img>
                                                </div>
                                                <hr className = "lineaSeparador" style = {{marginBottom: "10px"}}/>
                                                <div className = "FlexDirection-row AlignItems-flex-start JustifyContent-spaceBetween">
                                                    <img src = {"/img/productos/" + producto.Imagen} className = "imagenProductoPedido" alt = {producto.Producto.Referencia}></img>
                                                    <div className = "FlexDirection-column AlignItems-flex-start JustifyContent-flex-start datosProducto">
                                                        <p className = "helvetica cantidadesTallas">{textos[idioma].Talla + ": "  + stringDeTallas(producto.Tallas)}</p>
                                                        <div className = "FlexDirection-row AlignItems-center JustifyContent-spaceBetween">
                                                            <p className = "helvetica edicionTallas" onClick = {() => editarTallas(producto, index)}>{textos[idioma].EditarCantidades}</p>
                                                            <img data-tip data-for='textCantidadesResumen' src = "/img/iconos/signo-de-interrogacion.png" className = "iconoInterrogacion"></img>
                                                            {/*<ReactTooltip id='textCantidadesResumen' place="right" type="dark" effect="float">
                                                                <p>
                                                                    {textos[idioma].AyudaTallas}
                                                                </p>
                                                            </ReactTooltip>*/}
                                                        </div>
                                                        <Dropdown direction="right" isOpen={listaToggleState[index]} toggle={() => cambiarToggle(index)}>
                                                            <DropdownToggle caret>
                                                                {textos[idioma].Diseños}
                                                            </DropdownToggle>
                                                            <DropdownMenu >
                                                                {
                                                                    producto.Diseños.map((diseño, index) =>(        
                                                                            (diseño.DiseñoImagen !== null) ?
                                                                                <div className = "filaDiseño">
                                                                                    <DropdownItem header className = "bebas tituloDiseño" >{producto.Producto.Categoria.NombresPosiciones[index]["Nombre"+idioma]}</DropdownItem>
                                                                                    <DropdownItem className = "helvetica" >
                                                                                        
                                                                                            <div className = "elementoListaDiseños">
                                                                                                <p className = "helveticaBold">{textos[idioma].Imagen}</p>
                                                                                                {
                                                                                                    (diseño.DiseñoImagen.Tipo === "image") ? 
                                                                                                        <img src = {diseño.DiseñoImagen.pathImage} className = "imagenDiseñoPrevisualizacion" alt = "Design"></img>
                                                                                                        :
                                                                                                        <div className ="documentoPDF" id = "imagenPdf">
                                                                                                            <Document file={diseño.DiseñoImagen.pathImage}> <Page pageNumber={1} /></Document>
                                                                                                        </div>
                                                                                                }
                                                                                                <div className = "flexRowGeneral">
                                                                                                    <p className = "helveticaBold" style = {{marginRight: 10}}>{textos[idioma].TipoDiseño}:</p>
                                                                                                    <p className = "helvetica">{diseño.DiseñoImagen.TipoArte}</p>
                                                                                                </div>
                                                                                                <div className = "listaColoresDropdown">
                                                                                                    <p className = "helveticaBold">{textos[idioma].Colores}: </p>
                                                                                                    {
                                                                                                        diseño.DiseñoImagen.Colores.map((color) => (
                                                                                                            <div className = "cuadroColorImagenDropdown" style = {{backgroundColor: color}}></div>
                                                                                                        ))
                                                                                                    }
                                                                                                </div>
                                                                                                <div className = "flexRowGeneral">
                                                                                                    <p className = "helveticaBold" style = {{marginRight: 10}}>{textos[idioma].Tamaño}: </p>
                                                                                                    <p className = "helvetica">{diseño.DiseñoImagen.Tamaño}"</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <p className = "helveticaBold">{textos[idioma].NoRealizado}</p>
                                                                                    </DropdownItem>
                                                                                </div>
                                                                                :
                                                                                null
                                                                    ))
                                                                }
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <hr className = "lineaSeparador" style = {{marginBottom: "10px"}}/>
                                                <div className = "FlexDirection-row AlignItems-center JustifyContent-spaceBetween">
                                                    <p className = "bebas datosCostos" >{textos[idioma].Costo}</p>
                                                    <div className = "FlexDirection-column AlignItems-flex-end JustifyContent-center">
                                                        <p className = "bebas datosCostos" >{textos[idioma].CostoUnidad + ":  $ " + calculoUnitario(producto, false).toFixed(2)}</p>
                                                        <p className = "bebas datosCostos" >{textos[idioma].CostoTotal + ":  $ " + calculoPrecio(producto, true, false).toFixed(2)}</p>
                                                        {   (calculoCantidad(producto) > 30) ?
                                                                <div className = "FlexDirection-row" >
                                                                    <p className = "bebas" style = {{fontSize: "22px"}}>
                                                                        {(calculoCantidad(producto) > 100 ? "-10% $" : calculoCantidad(producto) > 60 ? "-8% $" : "-6% $")}</p>
                                                                    <p className = "bebas precioDescuento" style = {{marginRight: "10px"}}>
                                                                        {calculoPrecio(producto, false, false).toFixed(2)}</p>
                                                                </div>
                                                                : 
                                                                null 
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className = "FlexDirection-row JustifyContent-center AlignItems-center mt-5">
                                            <p className = "helveticaBold textoSinProducto">{textos[idioma].NoAñadido} </p>
                                        </div>

                                }
                            </div>
                        </div>
                        <div className = "pagoResumen">
                            <p className = "bebas tituloTotal" >{textos[idioma].TotalPedido}</p>
                            <hr className = "lineaSeparador" style = {{marginBottom: "10px"}}/>
                            <div className = "totalPedidoResumen">
                                <p className = "bebas tituloResumen" style = {{fontSize: "40px", margin: "0px 10px"}}>$ {totalPedido.toFixed(2)}</p>
                                {/*<p className = "bebas tituloResumen" style = {{fontSize: "20px", margin: "0px 10px"}}>{textos[idioma].IVA}</p>*/}
                            </div>
                            <p className = "botonGeneral bebas botonPagina1" onClick = {() => continuarPasos(2)}>{textos[idioma].ContinuarPedido}</p>
                        </div>

                        <Modal isOpen={modalVistaEdicion} toggle={toggleVistaEdicion} className = "ModalVistaPrevia" size = "lg">
                            <ModalHeader>{textos[idioma].Vistaprevia}</ModalHeader>
                            <ModalBody>
                                {
                                    (productoEdicion !== null) ?
                                    <div>
                                        <div className = "espacioTitulo">
                                            <div style = {{width: "100%"}} className = "centradoFlex">
                                                <p className = "bebas" style = {{fontSize: 28, margin: 0}} >{productoEdicion.Producto.Nombre}</p>
                                            </div>
                                        </div>
                                        <div className = "datosColumnas">
                                            <div className = "columnaDiseñosYColor">
                                                <div className = "diseñosResumen">
                                                    <div className = "FlexDirection-row JustifyContent-center AlignItems-center">
                                                        <p className = "bebas" style = {{fontSize: 25, margin: 0}} >{textos[idioma].TituloDiseños}</p>
                                                        <img data-tip data-for='textAyudaPosiciones' src = "/img/iconos/signo-de-interrogacion.png" className = "iconoInterrogacion"></img>
                                                        {/*<ReactTooltip id='textAyudaPosiciones' place="top" type="dark" effect="float">
                                                            <p>
                                                                {textos[idioma].AyudaPosicionesSeleccionadas}
                                                            </p>
                                                        </ReactTooltip>*/}
                                                    </div>
                                                    {
                                                            productoEdicion.Diseños.map((diseño, index) =>(
                                                                    (diseño.DiseñoImagen !== null) ?
                                                                        <div style = {{marginRight: 20}} key = {diseño.DiseñoImagen.pathImage}>
                                                                            <hr className = "lineaSeparador" style = {{marginLeft: 0, marginRight: 0, marginBottom: "10px"}}/>
                                                                            <div className = "espacioTituloDiseño">
                                                                                <p className = "bebas tituloDiseño" >{productoEdicion.Producto.Categoria.NombresPosiciones[index]["Nombre"+idioma]}</p>
                                                                            </div>
                                                                            <div className = "helvetica">
                                                                                    <div className = "elementoListaDiseños">
                                                                                        <p className = "helveticaBold">{textos[idioma].Imagen}</p>
                                                                                        {
                                                                                            (diseño.DiseñoImagen.Tipo === "image") ? 
                                                                                                <img src = {diseño.DiseñoImagen.pathImage} className = "imagenDiseñoPrevisualizacion" alt = "Design"></img>
                                                                                                :
                                                                                                <div className ="documentoPDF" id = "imagenPdf">
                                                                                                    <Document file={diseño.DiseñoImagen.pathImage}> <Page pageNumber={1} /></Document>
                                                                                                </div>
                                                                                        }
                                                                                        <div className = "flexRowGeneral" style = {{marginTop: 10}}>
                                                                                            <p className = "helveticaBold" style = {{marginRight: 10}}>{textos[idioma].TipoArte}:</p>
                                                                                            <p className = "helvetica">{diseño.DiseñoImagen.TipoArte}</p>
                                                                                        </div>
                                                                                        <div className = "listaColoresDropdown">
                                                                                            <p className = "helveticaBold">{textos[idioma].Colores}: </p>
                                                                                            {
                                                                                                diseño.DiseñoImagen.Colores.map((color) => (
                                                                                                    <div className = "cuadroColorImagenDropdown" style = {{backgroundColor: color}}></div>
                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                        <div className = "flexRowGeneral">
                                                                                            <p className = "helveticaBold" style = {{marginRight: 10}}>{textos[idioma].Tamaño}: </p>
                                                                                            <p className = "helvetica">{diseño.DiseñoImagen.Tamaño}"</p>
                                                                                        </div>
                                                                                    </div>
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                    null
                                                            ))
                                                    }
                                                    <hr className = "lineaSeparador" style = {{marginLeft: 0, marginRight: 0, marginBottom: "10px"}}/>
                                                </div> 
                                                <div className = "mitadCP">
                                                    <img src = {"/img/productos/" + productoEdicion.Imagen}  className = "imagenHerramientaDiseño" alt = {productoEdicion.Producto.Referencia}></img>
                                                    <div className = "listaColoresDetalle">
                                                        {
                                                            (productoEdicion.Producto.Variantes.length > 4) ? 
                                                                <img src = "/img/iconos/flecha-izquierda.png" className = "flechaColor" alt = "Flecha-Izquierda" onClick = {() => movimientoColor(-1)}></img>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            productoEdicion.Producto.Variantes.map((variante, index) => (
                                                                (index >= colorInicialState &&  index < colorInicialState+4) ? 
                                                                    <div className = {"cuadroColor " + (productoEdicion.Imagen === productoEdicion.Referencia + "-" + variante.Color._id + (variante.ColorSecundario ?  "-" + variante.ColorSecundario._id : "") +".jpg" ? "bordeAdicional" : null)} 
                                                                        style = {color(variante)} 
                                                                        onClick = {() => cambioColor(variante)}></div>
                                                                    :
                                                                    null
                                                            ))
                                                        }
                                                        {
                                                            (productoEdicion.Producto.Variantes.length > 4) ? 
                                                                <img src = "/img/iconos/flecha-derecha.png" className = "flechaColor" alt = "Flecha-Derecha" onClick = {() => movimientoColor(1)}></img>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    { mensajeProductoNuevo ? <p className='producto-nuevo helveticaBold'>{textos[idioma].mensajeProductoNuevo}</p> : null }
                                                    <img data-tip data-for='textColores' src = "/img/iconos/signo-de-interrogacion.png" className = "iconoInterrogacion"></img>
                                                    {/*<ReactTooltip id='textColores' place="top" type="dark" effect="float">
                                                        <p>
                                                            {textos[idioma].AyudaColores}
                                                        </p>
                                                    </ReactTooltip>*/}
                                                </div>
                                            </div> 
                                            <div className = "columnaTallas">
                                                <p className = "bebas" style = {{fontSize: 25}}>{textos[idioma].Tallas}</p>
                                                <div className = "panelTallas">
                                                    {
                                                        productoEdicion.Producto.Variantes[0].Tallas.map((tallaInfo) =>(
                                                            <div className = "inputTalla">
                                                                <p style = {{marginBottom: 0}} className = "bebas">{tallaInfo.Talla}</p>
                                                                <Input type="text" name="texto"  className= "inputCantidad" style = {{margin: 0}}  onChange = {(e) => modificarTallasStateEdicion({...tallasStateEdicion, [tallaInfo.Talla]: parseInt(e.target.value)})} placeholder= {productoEdicion.Tallas[tallaInfo.Talla]}/>
                                                                <img src = "/img/iconos/mas.png" className = "iconoMas" alt = "More"></img>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleVistaEdicion} className = "helveticaBold" style = {{fontSize: 20}}>{textos[idioma].Atras}</Button>
                                <Button color="secondary" className = "helveticaBold" style = {{fontSize: 20}} onClick = {() => edicionTallasProducto()}>{textos[idioma].Actualizar}</Button>
                                <img data-tip data-for='textBotonCarrito' src = "/img/iconos/signo-de-interrogacion.png" className = "iconoInterrogacion"></img>
                                {/*<ReactTooltip id='textBotonCarrito' place="top" type="dark" effect="float">
                                    <p>
                                        {textos[idioma].InfoActualizar}
                                    </p>
                                </ReactTooltip>*/}
                            </ModalFooter>
                        </Modal>
                    </div>
                    :
                    (paginaState === 2) ? 
                        <div>
                            <div className = "tituloResumen">
                                <div className = "FlexDirection-row JustifyContent-spaceBetween AlignItems-center">
                                    <p className = "bebas" >{textos[idioma].ResumenPedido[1]}</p>
                                    <img data-tip data-for='textAyudaPosiciones' src = "/img/iconos/signo-de-interrogacion.png" className = "iconoInterrogacion" style = {{marginBottom: "1rem"}}></img>
                                    {/*<ReactTooltip id='textAyudaPosiciones' place="top" type="dark" effect="float">
                                        <p>
                                            {textos[idioma].AyudaFormulario}
                                        </p>
                                    </ReactTooltip>*/}
                                </div>
                                
                                <div className = "botonesGuia">
                                    <div className = "espacioBoton">
                                        <p className ="botonGuia" style = {stylePagina1}>1</p>    
                                        <p className ="infoPagina">{textos[idioma].ResumenPedido[0]}</p>    
                                    </div>
                                    <div className = "espacioBoton">
                                        <p className ="botonGuia" style = {stylePagina2}>2</p>    
                                        <p className ="infoPagina">{textos[idioma].ResumenPedido[1]}</p>   
                                    </div>
                                    <div className = "espacioBoton">
                                    <p className ="botonGuia" style = {stylePagina3}>3</p>   
                                    <p className ="infoPagina">{textos[idioma].ResumenPedido[2]}</p>    
                                    </div>
                                    <div className = "espacioBoton">
                                        <p className ="botonGuia" style = {stylePagina4}>4</p>   
                                        <p className ="infoPagina">{textos[idioma].ResumenPedido[3]}</p>    
                                    </div>
                                </div>
                            </div>
                            <div className = "formulariosCampo" style = {{marginTop: "0px"}}>
                                <div className = "formularioDerecha">
                                    <form action="#" method="post" className = "form-info">
                                        <div className = "email formulario helveticaBold">
                                            <label htmlFor="email" style = {{fontSize: "17px"}} className = "helveticaBold">(*) {textos[idioma].Correo}</label>
                                            <input type="email" placeholder={textos[idioma].CorreoSeleccion} name="Correo" id="correo_input" onChange = {(e) => validacion(e)} required></input>
                                        </div>
                                        <div className = "nombres formulario helveticaBold">
                                            <label htmlFor="nombres" style = {{fontSize: "17px"}} className = "helveticaBold">(*) {textos[idioma].Nombre}(s)</label>
                                            <input type="text" placeholder={textos[idioma].NombrePlaceHolder} name="Nombres" id="nombres_input" className = "capitalizado" onKeyUp = {(e) => bloqueoCaracteresEspeciales(e)} required></input>
                                        </div>
                                        <div className = "apellidos formulario helveticaBold">
                                            <label htmlFor="apellidos" style = {{fontSize: "17px"}} className = "helveticaBold">(*) {textos[idioma].Apellido}(s)</label>
                                            <input type="text" placeholder={textos[idioma].ApellidoPlaceHolder} name="Apellidos" className = "capitalizado" onKeyUp = {(e) => bloqueoCaracteresEspeciales(e)} id="apellidos_input" required></input>
                                        </div>
                                        <div className = "nombrePedido formulario helveticaBold">
                                            <label htmlFor="nombrePedido" style = {{fontSize: "17px"}} className = "helveticaBold">(*) {textos[idioma].NombrePedido}</label>
                                            <input type="text" placeholder={textos[idioma].NombrePedidoPlaceHolder} name="NombrePedido" className = "capitalizado" onKeyUp = {(e) => bloqueoCaracteresEspecialesExceptoNumeros(e)} id="nombrepedido_input" required></input>
                                        </div>
                                    </form>
                                </div>
                                <div className = "formularioIzquierda">
                                    <form action="#" method="post" className = "form-info">
                                        <div className = "row">
                                            <div className = "direccion formularioMitad helveticaBold">
                                                <label htmlFor="direccion" style = {{fontSize: "17px"}}>(*) {textos[idioma].ProvinciaCiudad}</label>
                                                <select className = "fontSelect"  name="Departamento_Ciudad" id="provincia_ciudad_input"  onChange = {(e) => validacion(e)} required>
                                                    <option hidden defaultValue>{textos[idioma].ProvinciaCiudadPlaceholder}</option>
                                                    <option value = "AB Alberta"> AB Alberta</option>
                                                    <option value = "BC British Columbia"> BC British Columbia</option>
                                                    <option value = "MB Manitoba"> MB Manitoba</option>
                                                    <option value = "NB New Brunswick"> NB New Brunswick</option>
                                                    <option value = "NF Newfoundland"> NF Newfoundland</option>
                                                    <option value = "NS Nova Scotia"> NS Nova Scotia</option>
                                                    <option value = "NU Nunavut"> NU Nunavut</option>
                                                    <option value = "NT Northwest Territories"> NT Northwest Territories</option>
                                                    <option value = "ON Ontario"> ON Ontario</option>
                                                    <option value = "PE Prince Edward Island"> PE Prince Edward Island</option>
                                                    <option value = "QC Quebec"> QC Quebec</option>
                                                    <option value = "SK Saskatchewan"> SK Saskatchewan</option>
                                                    <option value = "YT Yukon"> YT Yukon</option>
                                                </select>
                                            </div>
                                            <div className = "codigoPostal formularioMitad helveticaBold">
                                                <label htmlFor="codigoPostal" style = {{fontSize: "17px"}} className = "helveticaBold">(*) {textos[idioma].CodigoPostal}</label>
                                                <input type="text" placeholder= {textos[idioma].CodigoPostalSeleccion} name="CodigoPostal" onChange = {(e) => validacion(e)} id="codigoPostal_input" required></input>
                                            </div>
                                        </div>
                                        <div className = "row">
                                            <div className = "direccion formularioMitad helveticaBold" >
                                                <label htmlFor="direccion" style = {{fontSize: "17px"}}>(*) {textos[idioma].Direccion}</label>
                                                <input type="text" placeholder= {textos[idioma].DireccionPlaceHolder} name="Direccion" onChange = {(e) => validacion(e)} id="direccion_input" required></input>
                                            </div>
                                            <div className = "barrio formularioMitad helveticaBold" >
                                                <label htmlFor="barrio" style = {{fontSize: "17px"}}>(*)  {textos[idioma].Barrio}</label>
                                                <input type="text" placeholder= {textos[idioma].BarrioPlaceHolder} name="Barrio" onChange = {(e) => validacion(e)} id="barrio_input" required></input>
                                            </div>
                                        </div>
                                        <div className = "celular formulario helveticaBold">
                                            <div className = "espacioCelular">
                                                <label htmlFor="celular" style = {{fontSize: "17px"}} className = "helveticaBold">{textos[idioma].Celular}</label>
                                                <img data-tip data-for='textNumber' src = "img/iconos/signo-de-interrogacion.png" className = "iconoInterrogacion"></img>
                                                {/*<ReactTooltip id='textNumber' place="top" type="dark" effect="float">
                                                    <p>
                                                        {textos[idioma].TextoAyudaCelular1}<br/>{textos[idioma].TextoAyudaCelular2}<br/>{textos[idioma].TextoAyudaCelular3}
                                                    </p>
                                                </ReactTooltip>*/}
                                            </div>
                                            <input type="text" placeholder={"Eg: 4162348123"} name="Celular" onKeyUp = {(e) => bloqueoLetras(e)} id="celular_input" required></input>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            { (mostrarError) ? <p className = "errorUsuario helveticaBold">{textos[idioma].ErrorUsuario}</p> : null}
                            <div className="barraFinalDatos">
                                <Button color="secondary" className = "botonDetalleProducto helveticaBold" onClick = {() => continuarPasos(1)}>{textos[idioma].Atras}</Button>
                                <Button color="secondary" className = "botonDetalleProducto helveticaBold" onClick = {() => continuarPasos(3)}>{textos[idioma].Siguiente}</Button>
                            </div>
                        </div>
                        :
                        (paginaState === 3) ?
                            <div style = {{width: "100%"}}>
                                <div className = "tituloResumen">
                                    <p className = "bebas" >{textos[idioma].FinalizacionCompra}</p>
                                    <div className = "botonesGuia">
                                        <div className = "espacioBoton">
                                            <p className ="botonGuia" style = {stylePagina1}>1</p>    
                                            <p className ="infoPagina">{textos[idioma].ResumenPedido[0]}</p>    
                                        </div>
                                        <div className = "espacioBoton">
                                            <p className ="botonGuia" style = {stylePagina2}>2</p>    
                                            <p className ="infoPagina">{textos[idioma].ResumenPedido[1]}</p>   
                                        </div>
                                        <div className = "espacioBoton">
                                            <p className ="botonGuia" style = {stylePagina3}>3</p>   
                                            <p className ="infoPagina">{textos[idioma].ResumenPedido[2]}</p>    
                                        </div>
                                        <div className = "espacioBoton">
                                            <p className ="botonGuia" style = {stylePagina4}>4</p>   
                                            <p className ="infoPagina">{textos[idioma].ResumenPedido[3]}</p>    
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <p className = "FuenteBebas titulosPaso2" style = {{marginLeft: "30px"}}>{textos[idioma].ResumenPedido[2]}</p>
                                    <p className = "helveticaBold textoPago" >{textos[idioma].textoVerificacion1}</p>
                                    <p className = "helveticaBold textoPago" >{textos[idioma].textoVerificacion2}</p>
                                    <p className = "helveticaBold textoPago" >{textos[idioma].textoVerificacion3}</p>
                                    <div className = "grupoCodigo form-info helveticaBold">
                                        <input type="number"  ref= {refCodigo1} id = "codigo1" className = "inputCodigo" maxLength="1" onKeyUp = {() => {refCodigo2.current.focus()}}></input>
                                        <input type="number"  ref= {refCodigo2} id = "codigo2" className = "inputCodigo" maxLength="1" onKeyUp = {() => {refCodigo3.current.focus()}}></input>
                                        <input type="number"  ref= {refCodigo3} id = "codigo3" className = "inputCodigo" maxLength="1" onKeyUp = {() => {refCodigo4.current.focus()}}></input>
                                        <input type="number"  ref= {refCodigo4} id = "codigo4" className = "inputCodigo" maxLength="1" onKeyUp = {() => {refCodigo5.current.focus()}}></input>
                                        <input type="number"  ref= {refCodigo5} id = "codigo5" className = "inputCodigo" maxLength="1" onKeyUp = {() => {refCodigo6.current.focus()}}></input>
                                        <input type="number"  ref= {refCodigo6} id = "codigo6" className = "inputCodigo" maxLength="1" ></input>
                                    </div> 
                                </div>
                                <div className = "FlexDirection-column JustifyContent-flex-start AlignItems-flex-start">
                                    <div className = "FlexDirection-row JustifyContent-spaceBetween AlignItems-center">
                                        <Button color="success" className = "botonVerificar helveticaBold" onClick = {() => verificarCodigo()}>{textos[idioma].Verificar}</Button>
                                        {/*
                                        <div id = "root">
                                            <p className = "helveticaBold textoPago">{new Date().toLocaleTimeString()}</p>
                                        </div>
                                        */}
                                    </div>
                                    {
                                        (verificarState.estado) ?
                                            <p className = "helveticaBold textoPago" style = {{color: "red"}}>{verificarState.texto}</p>
                                            :
                                            null
                                    }
                                   
                                </div>
                                <div className="barraFinalDatos">
                                    <Button color="secondary" className = "botonDetalleProducto helveticaBold" onClick = {() => continuarPasos(2)}>{textos[idioma].Atras}</Button>
                                    <Button color="secondary" className = "botonDetalleProducto helveticaBold" onClick = {() => continuarPasos(4)}>{textos[idioma].Siguiente}</Button>
                                </div>
                            </div>
                            :
                            <div style = {{width: "100%"}}>
                                <div className = "tituloResumen">
                                    <p className = "bebas" >{textos[idioma].FinalizacionCompra}</p>
                                    <div className = "botonesGuia">
                                        <div className = "espacioBoton">
                                            <p className ="botonGuia" style = {stylePagina1}>1</p>    
                                            <p className ="infoPagina">{textos[idioma].ResumenPedido[0]}</p>    
                                        </div>
                                        <div className = "espacioBoton">
                                            <p className ="botonGuia" style = {stylePagina2}>2</p>    
                                            <p className ="infoPagina">{textos[idioma].ResumenPedido[1]}</p>   
                                        </div>
                                        <div className = "espacioBoton">
                                            <p className ="botonGuia" style = {stylePagina3}>3</p>   
                                            <p className ="infoPagina">{textos[idioma].ResumenPedido[2]}</p>    
                                        </div>
                                        <div className = "espacioBoton">
                                            <p className ="botonGuia" style = {stylePagina4}>4</p>   
                                            <p className ="infoPagina">{textos[idioma].ResumenPedido[3]}</p>    
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className = "helveticaBold textoPago" >{textos[idioma].TextoPago1}</p>
                                    <p className = "FuenteBebas titulosPaso2" style = {{marginLeft: "30px"}}>{textos[idioma].Total}: $ {totalPedido.toFixed(2)} + {(calcularImpuestos()).toFixed(2)} (IVA)</p>
                                    <div className = "form-info">
                                        <p className = "FuenteHelvetica textoPaso2" style = {{marginLeft: "30px", marginTop: "-15px"}}>{textos[idioma].TextoPago2}</p>
                                        <div style = {{display: "flex", flexDirection: "row", alignItems: "baseline", marginTop: "10px", marginLeft: "30px"}}> 
                                                <input type="text" placeholder={textos[idioma].CuponPlaceHolder} name="Cupon" id="cupon_input" required ></input>
                                                <p className = "botonCupon text-center FuenteBebas" style = {{cursor: "pointer"}} >{textos[idioma].Canjear}</p>
                                        </div>
                                    </div>
                                    <p className = "FuenteBebas titulosPaso2" style = {{marginLeft: "30px"}}>{textos[idioma].ResumenPedido[2]}</p>
                                    <p className = "FuenteHelvetica textoPaso2" style = {{marginLeft: "30px", marginTop: "-15px"}}>{textos[idioma].TextoPago3}</p>
                                    <div className = "espacioPaypal">
                                        {/* <PaypalCheckoutbutton order = {{customer: usuario.Nombres + " " + usuario.Apellidos, total: calculoTotalPedido(carrito, true), items: calculoItems()}} /> */}
                                    </div>
                                </div>
                            </div>
            }
            
        </div>
     );
}
 
export default ResumenPedido;




