import React, {useContext, useEffect, useState} from 'react';
import Vibrant from 'node-vibrant'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ReactLoading from 'react-loading';
import * as ReactTooltip from 'react-tooltip';

import html2canvas from 'html2canvas'


import { useParams } from "react-router";
import GeneralContext from '../../context/GeneralContext'
import Footer from '.././Footer/Footer'
import axios from 'axios'
import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup} from 'reactstrap';
import { Link } from 'react-router-dom';



import './static/css/HerramientaDiseño.css'

const HerramientaDiseño = ({loadingState, modificarLoadingState}) => {

    const GC = useContext(GeneralContext);
    const { productoDetalle, modificarProductoDetalle, barraBuscar, mostrarBarraBuscar, carrito, modificarCarrito , urlBack, idioma} = GC;

    const [imagenActual, modificarImagenActual] = useState((productoDetalle !== null) ? productoDetalle.Referencia + "-" +productoDetalle.Variantes[0].Color._id + ".jpg" : null);
    const [colorInicialState, modificarColorInicialState] = useState(0);

    const [textoModal, modificarTextoModal] = useState("");

    //------------------------------------------------------------------------------------
    //States Modal-----------------------------------------------------------------------
    const [modalVistaPrevia, setModalVistaPrevia] = useState(false);
    const toggleVistaPrevia = () => setModalVistaPrevia(!modalVistaPrevia);

    const [modalAviso, setModalAviso] = useState(false);
    const toggleAviso = () => setModalAviso(!modalAviso);

    const [modalAvisoPosicionSinGuardar, setModalAvisoPosicionSinGuardar] = useState(false);
    const toggleAvisoPosicionSinGuardar = () => setModalAvisoPosicionSinGuardar(!modalAvisoPosicionSinGuardar);
    
    //-------------------------------------------------------------------------------------
    //States simples-----------------------------------------------------------------------

    const [iteracionActual, modificarIteracionActual] = useState(0);
    const [posicionActual, modificarPosicionActual] = useState(1);

    const [impresionState, modificarImpresionState] = useState(null);

    const [bordadoState, modificarBordadoState] = useState(null);

    const [transferState, modificarTransferState] = useState(null);

    const previaActual = "frontal";

    
    let { idProducto } = useParams();


    const [diseñoImagenActual, modificarDiseñoImagenActual] = useState({Tipo: "image", pathImage: "/img/iconos/upload.png", Colores: null, TipoArte: "", Tamaño: 1});

    const [seleccionColoresState, modificarSeleccionColoresState] = useState([]);

    const [diseñosPosiciones, modificarDiseñosPosiciones] = useState([]);

    const [posicionColores, modificarPosicionColores] = useState("-415px");
    const [posicionDiseño, modificarPosicionDiseño] = useState("-417px");

    const [continuarState, modificarContinuarState] = useState(true);

    const [tallasState, modificarTallaState] = useState({S: 0, M: 0, L: 0, XL: 0, XXL: 0, XXXL: 0, XXXXL: 0})

    ///////////////////////////////////////////////////////////////////////////////////////////////

    const tamañosMaximos = {
        "60b52a7941c7941a70223104-1": {width: 80, height: 70},
        "60b52a7941c7941a70223104-2": {width: 80, height: 70},
        "60b52a7941c7941a70223104-3": {width: 140, height: 125},
        "60b52a7941c7941a70223104-4": {width: 65, height: 60},
        "60b52a7941c7941a70223104-5": {width: 70, height: 65},
        "60b52a7941c7941a70223104-6": {width: 80, height: 70},
        "60b52a7941c7941a70223104-7": {width: 130, height: 120},
        "60b52a7941c7941a70223104-8": {width: 80, height: 75},
        "60b52a7a41c7941a70223120-1": {width: 140, height: 125},
        "60b52a7a41c7941a70223120-2": {width: 110, height: 90},
        "60b52a7a41c7941a70223120-3": {width: 110, height: 90},
        "60b52a7a41c7941a70223120-4": {width: 70, height: 65},
        "60b52a7a41c7941a70223120-5": { width: 70, height: 70},
        "60b52a7a41c7941a70223120-6": {width: 80, height: 65},
        "60b52a7a41c7941a70223120-7": {width: 80, height: 65},
        "60b52a7a41c7941a70223120-8": {width: 110, height: 100},
        "60b52a7a41c7941a70223120-9": {width: 110, height: 100},
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    
    const textos = {
        ES: {
            AnchoEst: "Ancho estandar",
            AnchoMaximo: "Ancho maximo",
            Tamaño: "Tamaño",
            Colores: "Colores",
            Diseño: "Diseño",
            Aviso: "Aviso",
            ImagenColores: "Colores imagen", 
            GuardarD: "Guardar diseño",
            EliminarD: "Eliminar diseño",
            Impresion: "Impresion",
            Bordado: "Bordado",
            AñadirCarrito: "Añadir al carrito", 
            Cerrar: "Cerrar",
            IrCarrito: "Ir al carrito", 
            DiseñoNoGuardado: "Hay un diseño cargado en la posicion actual sin guardar, si cambia de posicion este se perdera, ¿Desea continuar sin guardar?",
            Continuar: "Continuar",
            TipoDiseño: "Debe seleccionar que tipo de arte desea (Impresión, bordado)",
            AñadidoCorrectamente: "Se añadio correctamente",
            DiseñoPosicion: "¡El diseño para la posicion ",
            GuardadoExito: " a sido GUARDADO con exito!",
            EliminadoExito: " a sido ELIMINADO con exito!",
            TipoArte: "Tipo de arte",
            TituloDiseños: "Posiciones seleccionadas",
            Continuar: "Continuar",
            Tallas: "Tallas",
            DiseñoGuardado: "Tu diseño fue guardado en esta posicion",
            AyudaDiseños: "Las posiciones con diseño guardado apareceran con un checking, tambien podras borrar los diseños subidos individualmente.",
            Vistaprevia: "Seleccion de tallas y color",
            Atras: "Atras",
            AyudaPosicionesSeleccionadas: "Aqui veras un resumen de los diseños y posiciones selecionadas para tu producto, si quieres modificar o añadir diseños o posiciones vuelve a la pantalla anterior",
            AyudaColores: "Puedes visualizar todos los colores del producto, al añadir al carrito se agregara el producto con el color seleccionado",
            AyudaBotonCarrito: 'Al seleccionar "añadir al carrito" se añadira un producto con los diseños en las posiciones cargadas previamente, con el color seleccionado y las cantidades en cada talla ingresadas',
            SinCantidadesTallas: "Debe ingresar la cantidad en alguna talla para añadir",
            TipoDeArteCargado: "En uno de los diseños cargados no ha seleccionado el tipo de arte, por favor elija uno para continuar",
            ContinuarAñadiendo: "Continuar añadiendo",
            AyudacoloresDiseño: "Aqui veras los colores identificados en tu diseño, si deseas elminar alguno seleccionalo y veras el icono de borrado, ¡Asi solo dejaras los colores que prefieras para tu diseño!",
            Transfer: "Transferencia",
            mensajeCargando: "Cargando...",
        },
        EN: {
            AnchoEst: "Standard width",
            AnchoMaximo: "Maximum width",
            Tamaño: "Size",
            Colores: "Colors",
            Diseño: "Design",
            Aviso: "Warning",
            ImagenColores: "Colors image", 
            GuardarD: "Save design",
            EliminarD: "Delete design",
            Impresion: "Print",
            Bordado: "Embroidery",
            AñadirCarrito: "Add to cart", 
            Cerrar: "Close",
            IrCarrito: "Go to the cart", 
            DiseñoNoGuardado: "There is a design loaded in the current position without saving, if you change position it will be lost. Do you want to continue without saving?",
            Continuar: "Continue",
            TipoDiseño: "You must select what type of art you want (Printing, embroidery)",
            AñadidoCorrectamente: "Was added successfully",
            DiseñoPosicion: "The design for the position ",
            GuardadoExito: " has been SAVED successfully!",
            EliminadoExito: " has been DELETED successfully!",
            TipoArte: "Type of art",
            TituloDiseños: "Selected positions",
            Continuar: "Continue",
            Tallas: "Sizes",
            DiseñoGuardado: "Your design was saved in this position",
            AyudaDiseños: "The positions with a saved design will appear with a check, you can also delete the designs uploaded individually.",
            Vistaprevia: "Selection of sizes and colours",
            Atras: "Back",
            AyudaPosicionesSeleccionadas: "Here you will see a summary of the designs and positions selected for your product, if you want to modify or add designs or positions return to the previous screen",
            AyudaColores: "You can view all the colors of the product, when adding to the cart the product with the selected color will be added",
            AyudaBotonCarrito: 'When selecting "add to cart" a product will be added with the designs in the previously loaded positions, with the selected color and the quantities in each size entered',
            SinCantidadesTallas: "You must enter the quantity in some size to add",
            TipoDeArteCargado: "In one of the uploaded designs you have not selected the art type, please choose one to continue",
            ContinuarAñadiendo: "Continue Adding",
            AyudaColoresDiseño: "Here you will see the colors identified in your design, if you want to delete any select it and you will see the delete icon, so you will only leave the colors you prefer for your design!",
            Transfer: "Transfer",
            mensajeCargando: "Loading...",
        }
    }

    //Funcion auxiliares----------------------------------------------------------------------------------------------------------------------------------------------------------------------


    const llenarDiseñosPosiciones = () => {
        let arregloDiseños = []
        for (let i = 0; i < productoDetalle.Categoria.NombresPosiciones.length; i++) {
            arregloDiseños.push({DiseñoImagen: null})
        }
        modificarDiseñosPosiciones(arregloDiseños)
    }

    const cargaCanvas = async() =>{
        modificarLoadingState({...loadingState, estado: true})
        modificarLoadingState({estado: true, texto: textos[idioma].mensajeCargando})
        var img = document.getElementById("imagenPdf")
        setTimeout(() => {
                html2canvas(img).then((canv) => {
                    var dataImg  = canv.toDataURL()

                    let listaColores = [];
                    let listaSeleccionColores = [];

                    Vibrant.from(dataImg).quality(10).getPalette().then((palette) => {
                        for(let hex in palette){
                            if(palette.hasOwnProperty(hex) && palette[hex] && !listaColores.includes(palette[hex].getHex()) && palette[hex].getPopulation() > 0){
                                listaColores.push(palette[hex].getHex());   
                                listaSeleccionColores.push(false);  
                            }
                        }  
                        if(JSON.stringify(diseñoImagenActual.Colores) !== JSON.stringify(listaColores)){
                            modificarDiseñoImagenActual({...diseñoImagenActual, Colores: listaColores});
                            modificarSeleccionColoresState(listaSeleccionColores); 
                            guardarDiseño({...diseñoImagenActual, Colores: listaColores}, -1)
                        }
                        modificarLoadingState({...loadingState, estado: false})
                    })
                })
        }, 200);
    }

    const setearTamaños = () =>{
        var all = document.getElementsByClassName('react-pdf__Page__canvas');
        setTimeout(() => {
            all[0].className += " diseñoPosicion"
        }, 10);
    }
    

    const modificacionTamaño = (e) =>{
        if(diseñoImagenActual.Tipo === "pdf")
            setearTamaños()
        modificarDiseñoImagenActual({...diseñoImagenActual, Tamaño: e.target.value})
        if(diseñosPosiciones[posicionActual-1].DiseñoImagen !== null)
            guardarDiseño({...diseñoImagenActual, Tamaño: e.target.value}, -1)
    }

    const comprobarDiferenciaColores = (hex, listaColores) =>{
        for(let hexs of listaColores){
            if(Vibrant.Util.hexDiff(hex, hexs) < 20)
                return false
        }

        return true;
    }

    const comprobarDiferenciaBlanco = (hex) =>{
        return Vibrant.Util.hexDiff(hex, "#ffffff") < 15
    }

    //----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    const onFileChanges = (e) => {
        if(e.target.files && e.target.files.length > 0){
            const file = e.target.files[0]
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = function load() {
                if(file.type.includes("image")){
                    let listaColores = []
                    let listaSeleccionColores = []
                    modificarLoadingState({estado: true, texto: textos[idioma].mensajeCargando})
                    Vibrant.from(reader.result).quality(1).clearFilters().getPalette().then((palette) => {
                        for(let hex in palette){
                            if(palette.hasOwnProperty(hex) && palette[hex] && !listaColores.includes(palette[hex].getHex()) && palette[hex].getPopulation() > 0 && comprobarDiferenciaColores(palette[hex].getHex(), listaColores)){
                                listaColores.push(palette[hex].getHex()); 
                                listaSeleccionColores.push(false);  
                            }
                        }  
                    })
                    setTimeout(() => {
                        modificarDiseñoImagenActual({...diseñoImagenActual, Tipo: (file.type.includes("image")) ? "image" : "pdf", pathImage: reader.result, Colores: listaColores});  
                        modificarSeleccionColoresState(listaSeleccionColores);
                        modificarLoadingState({...loadingState, estado: false})
                        guardarDiseño({...diseñoImagenActual, Tipo: (file.type.includes("image")) ? "image" : "pdf", pathImage: reader.result, Colores: listaColores}, -1);
                    }, 1000);
                }
                else{
                    modificarDiseñoImagenActual({...diseñoImagenActual, Tipo: (file.type.includes("image")) ? "image" : "pdf", pathImage: reader.result});  
                    modificarSeleccionColoresState([]);
                }
            }
        }
    }

    const activarColor = (index) => {
        let listaSeleccionActual = seleccionColoresState.slice(0);
        for(let i = 0; i < listaSeleccionActual.length; i++) {
            if(i !== index) {
                listaSeleccionActual[i] = false;
            }
        }
        listaSeleccionActual[index] = !listaSeleccionActual[index];

        modificarSeleccionColoresState(listaSeleccionActual);
    }

    const comprobarBotonColorSeleccionado = () => {
        for(let color of seleccionColoresState){
            if(color)
                return true;
        }
        return false;
    }


    const comprobarPosicionValida = (posicionComprobar, lista) => {
        for(let i in lista){
            if(posicionComprobar === parseInt(lista[i]))
                return true
        }

        return false
    }
    const eliminarColorLista = () => {
        let listaSeleccionColoresActuales = seleccionColoresState.slice();
        let listaColoresActual = diseñoImagenActual.Colores.slice();
        let i = listaSeleccionColoresActuales.indexOf(true);
 
        if ( i !== -1 && listaSeleccionColoresActuales.length > 1) {
            listaSeleccionColoresActuales.splice(i, 1);
            listaColoresActual.splice(i, 1)
        }

        modificarSeleccionColoresState(listaSeleccionColoresActuales);
        modificarDiseñoImagenActual({...diseñoImagenActual, Colores: listaColoresActual});
    }

    const movimientoColor = (movimiento) => {
        if(colorInicialState+4 < productoDetalle.Variantes.length && movimiento === 1)
            modificarColorInicialState(colorInicialState + movimiento);
        else if(colorInicialState > 0 && movimiento === -1)
            modificarColorInicialState(colorInicialState-1)
    }

    const movimientoPosicion = (posicion) => {
        modificarPosicionActual(parseInt(posicion));
        modificarTextoModal("");
        modificarDiseñoImagenActual({File: null, pathImage: "/img/iconos/upload.png", Colores: null, TipoArte: "", Tamaño: 1});
        let botonSubir = document.getElementById("botonSubirImagen");
        botonSubir.value = "";
        modificarImpresionState(comprobarPosicionValida(posicion, productoDetalle.PosicionesValidas.Impresion))
        modificarBordadoState(comprobarPosicionValida(posicion, productoDetalle.PosicionesValidas.Bordado))
        modificarTransferState(comprobarPosicionValida(posicion, productoDetalle.PosicionesValidas.Transferencia))
    }

    const cambioColor = (variante) => {
        modificarImagenActual(productoDetalle.Referencia + "-" + variante.Color._id +(variante.ColorSecundario ?  "-" + variante.ColorSecundario._id : "") + ".jpg")
    }


    const guardarDiseño = (diseño, posicion) => {
        /*if(diseñoImagenActual.TipoArte === ""){
            modificarTextoModal(textos[idioma].TipoDiseño);
            setModalAviso(true);
        }
        else{*/
        let listaActual = diseñosPosiciones.slice(0);
        if(posicion === -1)
            listaActual[posicionActual-1].DiseñoImagen = diseño;
        else
            listaActual[posicion].DiseñoImagen = diseño;
        modificarDiseñosPosiciones(listaActual);
        //}
    }

    const eliminarDiseño = (posicion) => {
        let listaActual = diseñosPosiciones.slice();
        listaActual[posicion-1].DiseñoImagen = null;
        modificarDiseñosPosiciones(listaActual);
        modificarDiseñoImagenActual({File: null, pathImage: "/img/iconos/upload.png", Colores: null, TipoArte: "", Tamaño: 1});
        modificarTextoModal(textos[idioma].DiseñoPosicion + productoDetalle.Categoria.NombresPosiciones[posicionActual-1]["Nombre"+idioma] + textos[idioma].EliminadoExito);
        setModalAviso(true);
        setModalAvisoPosicionSinGuardar(false);
    }

    const modificarTipoArte = (diseño) =>{
        modificarDiseñoImagenActual(diseño);
        guardarDiseño(diseño, -1)
    }
    
    const obtenerProductoDetalle = React.useCallback(async () =>{
        modificarLoadingState({estado: true, texto: textos[idioma].mensajeCargando})
        await axios.post(urlBack + "Api/Producto/GetProductos",{
            "data": {
                "Ids":[
                    {
                        "_id": idProducto
                    }
                ],
                "Categorias":[
        
                ],
                "Subcategorias":[
                    
                ],
                "Generos":[
                    
                ],
                "Marcas":[
                    
                ],
                "Tallas":[
                    
                ],
                "Colores":[
                    
                ],
                "ParametroOrdenamiento": "Categoria"
            }
        }).then(response => {
            modificarProductoDetalle(response.data.data[0]);
            modificarLoadingState({estado: false, texto: ""})
        }).catch(function(err) {
            console.log("Hubo un error al consultar", err)
            modificarLoadingState({estado: false, texto: ""})
        });
    }, [urlBack, modificarProductoDetalle, idProducto])

    const revisarCantidadesTallas = () =>{
        let bandera = false;
        for(let talla in tallasState){
            if(tallasState[talla] > 0)
                bandera = true;
        }
        return bandera;
    }
    const añadirAlCarrito = () =>{
        if(revisarCantidadesTallas()){
            let carritoActual = carrito.slice();
            const diseñosActuales = JSON.parse(JSON.stringify(diseñosPosiciones));
            carritoActual.push({Producto: productoDetalle, Diseños: diseñosActuales, Imagen: imagenActual, Tallas: tallasState});
            modificarCarrito(carritoActual);
            //llenarDiseñosPosiciones()
            //modificarDiseñosPosiciones(diseñosActuales)
            sessionStorage.clear();
            sessionStorage.setItem("Carrito", JSON.stringify(carritoActual));
            //modificarDiseñoImagenActual({File: null, pathImage: "/img/iconos/upload.png", Colores: null, TipoArte: "", Tamaño: 1});
            
            //llenarDiseñosPosiciones()

            setModalAviso(true);
            modificarTextoModal(textos[idioma].AñadidoCorrectamente);
        }
        else{
            setModalAviso(true);
            modificarTextoModal(textos[idioma].SinCantidadesTallas);
        }
    }

    const cerrarModalAviso = () =>{
        setModalAviso(false);
        modificarTextoModal("");
    }

    const color = (variante) => {
        return (variante.ColorSecundario)  ? {"background": "linear-gradient(to right,"+ variante.Color.Hexadecimal+ "  0%,"+ variante.Color.Hexadecimal+ " 50%, "+ variante.ColorSecundario.Hexadecimal+ " 50%, "+ variante.ColorSecundario.Hexadecimal+ " 100%)"} : {"backgroundColor": variante.Color.Hexadecimal}
    }
    const revisarListaDiseños = () =>{
        let bandera = false;
        for(let diseño in diseñosPosiciones){
            if(diseñosPosiciones[diseño].DiseñoImagen !== null)
                bandera = (diseñosPosiciones[diseño].DiseñoImagen.TipoArte !== "");
        }
        return bandera;
    }

    const continuar = (cambio) =>{
        if(revisarListaDiseños()){
            modificarTallaState({S: 0, M: 0, L: 0, XL: 0, XXL: 0, XXXL: 0, XXXXL: 0})
            setModalVistaPrevia(true);
        }
        else{
            setModalAviso(true);
            modificarTextoModal(textos[idioma].TipoDeArteCargado);
        }
    }


    useEffect(()=>{
        if(productoDetalle === null)  
             obtenerProductoDetalle();
        if(imagenActual === null && productoDetalle !== null)
            modificarImagenActual(productoDetalle.Referencia + "-" +productoDetalle.Variantes[0].Color._id + ".jpg");
        if(productoDetalle !== null){
            if(diseñosPosiciones.length === 0){
                modificarTallaState({S: 0, M: 0, L: 0, XL: 0, XXL: 0, XXXL: 0, XXXXL: 0})
                modificarContinuarState(false)
                llenarDiseñosPosiciones()
            }
            if(impresionState === null)
                modificarImpresionState(comprobarPosicionValida(parseInt(productoDetalle.Posiciones[0]), productoDetalle.PosicionesValidas.Impresion))
            if(bordadoState === null)
                modificarBordadoState(comprobarPosicionValida(parseInt(productoDetalle.Posiciones[0]), productoDetalle.PosicionesValidas.Bordado))
            if(transferState === null)
                modificarTransferState(comprobarPosicionValida(parseInt(productoDetalle.Posiciones[0]), productoDetalle.PosicionesValidas.Transferencia))
            if(diseñosPosiciones.length !== 0){
                if(diseñosPosiciones[posicionActual-1].DiseñoImagen !== null && diseñoImagenActual.pathImage === "/img/iconos/upload.png")
                    modificarDiseñoImagenActual(diseñosPosiciones[posicionActual-1].DiseñoImagen);
            }
        }
        
    }, [productoDetalle, obtenerProductoDetalle, imagenActual, barraBuscar, mostrarBarraBuscar, diseñosPosiciones, posicionActual, diseñoImagenActual.pathImage, impresionState, transferState])
    return ( 
            <div>
                {
                    productoDetalle !== null ?
                        <div className = "espacioHerramienta">
                            <hr className = "lineaSeparador"/>
                            <div className = "tituloProductoDiseño">
                                <p className = "bebas">{productoDetalle.Nombre}</p>
                            </div>
                            <div className = "cuerpoHerramienta">
                                <div className = "listaPosicionesSeleccion">
                                    {
                                            productoDetalle.Posiciones.map((posicion) => (
                                                <div className = "imagePosicionLista" key = {posicion}>
                                                    <img src = {"/img/herramientaDiseno/"+((productoDetalle.Categoria._id === "60b52a7a41c7941a70223123") ? "60b52a7941c7941a70223104" : productoDetalle.Categoria._id)+"-"+posicion+".jpg"} 
                                                        className = {(parseInt(posicion) === posicionActual) ? 
                                                                        "Seleccionado CN-Seleccion-" + ((productoDetalle.Categoria._id === "60b52a7a41c7941a70223123") ? "60b52a7941c7941a70223104" : productoDetalle.Categoria._id)
                                                                        : 
                                                                        "CN-Seleccion-" + ((productoDetalle.Categoria._id === "60b52a7a41c7941a70223123") ? "60b52a7941c7941a70223104" : productoDetalle.Categoria._id)}
                                                        alt = "Posicionamiento diseño"
                                                        onClick = {() => movimientoPosicion(parseInt(posicion))}
                                                    />
                                                    {
                                                        (diseñosPosiciones[posicion-1]) ?
                                                            (diseñosPosiciones[posicion-1].DiseñoImagen !== null) ? 
                                                                <div className = "FlexDirection-column JustifyContent-center Aligitems-center">
                                                                    <img src = "/img/iconos/check.png" className = "checkPosicion" title = {textos[idioma].DiseñoGuardado}/>
                                                                    <img src = "/img/iconos/eliminar.png" className = "checkPosicion" onClick = {() => eliminarDiseño(posicion)} title = {textos[idioma].EliminarD}/>
                                                                </div>
                                                                :
                                                                null
                                                            :
                                                            null
                                                    }
                                                </div>
                                            ))
                                    }
                                    <img data-tip data-for='textDiseños' src = "/img/iconos/signo-de-interrogacion.png" className = "iconoInterrogacionDiseños"></img>
                                    {/*
                                    <ReactTooltip id='textDiseños' place="top" type="dark" effect="float">
                                        <p>
                                            {textos[idioma].AyudaDiseños}
                                        </p>
                                    </ReactTooltip>
                                    */}
                                </div>
                                <div className = "visualizadorPosiciones">
                                    <div className = "diseñoEsquema">
                                        <img src = {"/img/herramientaDiseno/"+((productoDetalle.Categoria._id === "60b52a7a41c7941a70223123") ? "60b52a7941c7941a70223104" : productoDetalle.Categoria._id)+"-"+posicionActual+".jpg"} 
                                                className = {"CN-" + ((productoDetalle.Categoria._id === "60b52a7a41c7941a70223123") ? "60b52a7941c7941a70223104" : productoDetalle.Categoria._id) + " posicionGeneralPrevisualizacion"}
                                                alt = "Posicionamiento diseño"
                                        />
                                        {
                                            (diseñoImagenActual.pathImage !== "/img/iconos/upload.png") ? 
                                                (diseñoImagenActual.Tipo === "image") ?
                                                    <div className = {"IDP-" + ((productoDetalle.Categoria._id === "60b52a7a41c7941a70223123") ? "60b52a7941c7941a70223104" : productoDetalle.Categoria._id) + "-" + posicionActual + " posicionGeneralPrevisualizacion"}>
                                                        <img src = {diseñoImagenActual.pathImage} id = "imagenPosicion"  
                                                            style = {{width: ((50+((50/((productoDetalle.Categoria.NombresPosiciones[iteracionActual].AnchoMaximo-1)/0.1))*((diseñoImagenActual.Tamaño-1)/0.1)))+"%"),
                                                                    height: ((50+((50/((productoDetalle.Categoria.NombresPosiciones[iteracionActual].AnchoMaximo-1)/0.1))*((diseñoImagenActual.Tamaño-1)/0.1)))+"%")}}
                                                            className = "diseñoPosicion"
                                                            alt = "imagenDiseñoPosicion"
                                                        ></img>
                                                    </div>
                                                    :
                                                    <div className = {"IDP-" + ((productoDetalle.Categoria._id === "60b52a7a41c7941a70223123") ? "60b52a7941c7941a70223104" : productoDetalle.Categoria._id) + "-" + posicionActual + " posicionGeneralPrevisualizacion"}>
                                                        <Document file={diseñoImagenActual.pathImage} onLoadSuccess = {() => setearTamaños()} className = "tamañoPadre diseñoPosicion"> 
                                                            <Page 
                                                                pageNumber={1} 
                                                                canvasBackground = "transparent"
                                                                className = "tamañoPadre diseñoPosicion"
                                                                width= {parseInt(tamañosMaximos[((productoDetalle.Categoria._id === "60b52a7a41c7941a70223123") ? "60b52a7941c7941a70223104" : productoDetalle.Categoria._id) + "-" + posicionActual].width * ((50+((50/((productoDetalle.Categoria.NombresPosiciones[iteracionActual].AnchoMaximo-1)/0.1))*((diseñoImagenActual.Tamaño-1)/0.1))) /100))}
                                                                height= {parseInt(tamañosMaximos[((productoDetalle.Categoria._id === "60b52a7a41c7941a70223123") ? "60b52a7941c7941a70223104" : productoDetalle.Categoria._id) + "-" + posicionActual].height * ((50+((50/((productoDetalle.Categoria.NombresPosiciones[iteracionActual].AnchoMaximo-1)/0.1))*((diseñoImagenActual.Tamaño-1)/0.1))) / 100))}
                                                            />
                                                        </Document>
                                                    </div>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="cuadroPosiciones">
                                        <hr className = "lineaSeparador"  style = {{marginTop: 0}}/>
                                        <div className = "listaPosiciones">
                                            <p className= "bebas" style = {{fontSize: "35px", paddingTop: "5px", margin: 0}}>{productoDetalle.Categoria.NombresPosiciones[posicionActual-1]["Nombre"+idioma]}</p>
                                        </div>
                                        <hr className = "lineaSeparador" style = {{marginTop: 0}}/>
                                        <p className= "bebas" style = {{fontSize: "23px", margin: 0}}>{textos[idioma].AnchoEst}: {productoDetalle.Categoria.NombresPosiciones[iteracionActual].AnchoEstandar}" / {textos[idioma].AnchoMaximo}: {productoDetalle.Categoria.NombresPosiciones[iteracionActual].AnchoMaximo}"</p>
                                        <hr className = "lineaSeparador" style = {{marginTop: 0}}/>
                                        <div className = "barraTamaño">
                                            <p className= "bebas" style = {{fontSize: "25px", marginRight: "10px", paddingTop: "10px"}}>{textos[idioma].Tamaño}</p>
                                            <input type="range" defaultValue = "1" min = {1} max= {productoDetalle.Categoria.NombresPosiciones[iteracionActual].AnchoMaximo}   step="0.1" onChange={(e) => modificacionTamaño(e)}></input>
                                            <p className= "bebas" style = {{fontSize: "25px", marginLeft: "10px", paddingTop: "10px", width: "22px"}}>{diseñoImagenActual.Tamaño}"</p>
                                        </div> 
                                    </div>    
                                </div>
                                <div className = "botonesEdicion">
                                    <div className = "cuadroPersonalizacionDiseño" style = {{right: posicionDiseño}}>
                                        <div className = "mitadCP">
                                            <div className = "BotonesPersonalizacion">
                                                <div>
                                                    <input
                                                        type="file"
                                                        onChange={onFileChanges}
                                                        content = "Subir imagen"
                                                        id = "botonSubirImagen"
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className = "espacioImagenPrevisualizacion">
                                                {
                                                    (diseñoImagenActual.Tipo === "image") ?
                                                        <img src = {diseñoImagenActual.pathImage} alt = "imagenDiseño" className = "imagenDiseñoPrevisualizacion" type="application/pdf"></img>
                                                        :
                                                        <div className ="documentoPDF" id = "imagenPdf">
                                                            <Document file={diseñoImagenActual.pathImage} onLoadSuccess={() => cargaCanvas()}> <Page pageNumber={1} /></Document>
                                                        </div>
                                                }                                   
                                                {
                                                    
                                                    (loadingState.estado) ?
                                                        <div className = "coloresImagen">
                                                            <ReactLoading type={"spin"} color={"#000"} height={35} width={35} />
                                                            <p className = "helveticaBold textoSpin" style = {{color: "#000"}}>{loadingState.texto}</p>
                                                        </div>
                                                        :
                                                        (diseñoImagenActual.Colores !== null) ?
                                                            <div className = "coloresImagen">
                                                                <div className = "FlexDirection-row JustifyContent-spaceBetween AlignItems-center">
                                                                    <p className = "helveticaBold">{textos[idioma].ImagenColores}</p>
                                                                    {/*
                                                                    <img data-tip data-for='textDiseños' src = "/img/iconos/signo-de-interrogacion.png" className = "iconoInterrogacion" style = {{marginBottom: "1rem"}}></img>
                                                                    <ReactTooltip id='textDiseños' place="top" type="dark" effect="float">
                                                                        <p>
                                                                            {textos[idioma].AyudaColoresDiseño}
                                                                        </p>
                                                                    </ReactTooltip>
                                                                    */}
                                                                </div>

                                                                <div className = "panelColoresImagen">
                                                                    <div className = "listaColoresImagen">
                                                                        {
                                                                            diseñoImagenActual.Colores.map((color, index) =>(
                                                                                <div key = {color} className = {(seleccionColoresState[index]) ? 
                                                                                                            "cuadroColorImagen strikeNegro"
                                                                                                            :
                                                                                                            (comprobarDiferenciaBlanco(color)) ? "cuadroColorImagen bordeColor" : "cuadroColorImagen"} style = {{backgroundColor: color}} onClick = {() => activarColor(index)}></div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                    {
                                                                        (comprobarBotonColorSeleccionado()) ? 
                                                                            <div className = "panelBotonEliminacionColor">
                                                                                        <img className = "botonEliminarColor" src = "/img/iconos/eliminar.png" onClick = {() => eliminarColorLista()} alt = "Delete color"></img>
                                                                                
                                                                            </div>  
                                                                            :
                                                                            null
                                                                    } 
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                }
                                            </div>  
                                        </div>
                                        <div className = "botonProducto" onClick = {() => modificarPosicionDiseño((posicionDiseño === "-417px") ? "-20px": "-417px")}>
                                            <img   img src = "/img/iconos/flecha-izquierda.png" className = {(posicionDiseño === "-417px") ? "flechaBoton" : "flechaBoton giro180Grados"} alt = "Flecha-Izquierda"></img>
                                            <p style = {{fontSize: "25px", fontWeight: "bold"}} className = "bebas giro90Grados">{textos[idioma].Diseño}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className = "lineaSeparador"/>
                            <div className = "barraEdicion">
                                <div className = "opcionesArte">
                                    {
                                        (impresionState) ? 
                                            <FormGroup>
                                                <Label className = "radioButton bebas">
                                                    <Input type="radio" checked = {diseñoImagenActual.TipoArte === textos[idioma].Impresion} name = "arte" onClick = {() => modificarTipoArte({...diseñoImagenActual, TipoArte: textos[idioma].Impresion})}/> {textos[idioma].Impresion}
                                                </Label>
                                            </FormGroup>
                                            :
                                            null
                                    }
                                    {
                                        (bordadoState) ? 
                                            <FormGroup>
                                                <Label className = "radioButton bebas">
                                                    <Input type="radio" checked= {diseñoImagenActual.TipoArte === textos[idioma].Bordado} name = "arte" onClick = {() => modificarTipoArte({...diseñoImagenActual, TipoArte: textos[idioma].Bordado})}/> {textos[idioma].Bordado}
                                                </Label>
                                            </FormGroup>
                                            :
                                            null
                                    }
                                    {
                                        (transferState) ? 
                                            <FormGroup>
                                                <Label className = "radioButton bebas">
                                                    <Input type="radio" checked= {diseñoImagenActual.TipoArte === textos[idioma].Transfer} name = "arte" onClick = {() => modificarTipoArte({...diseñoImagenActual, TipoArte: textos[idioma].Transfer})}/> {textos[idioma].Transfer}
                                                </Label>
                                            </FormGroup>
                                            :
                                            null
                                    }
                                </div>
                                <p className = "botonGeneral bebas" onClick = {() => continuar(true)}>{textos[idioma].Continuar}</p>
                            </div>
                            <Modal isOpen={modalVistaPrevia} toggle={toggleVistaPrevia} className = "ModalVistaPrevia" size = "lg">
                                <ModalHeader>{textos[idioma].Vistaprevia}</ModalHeader>
                                <ModalBody>
                                    <div>
                                        <div className = "espacioTitulo">
                                            <div style = {{width: "100%"}} className = "centradoFlex">
                                                <p className = "bebas" style = {{fontSize: 28, margin: 0}} >{productoDetalle.Nombre}</p>
                                            </div>
                                        </div>
                                        <div className = "datosColumnas">
                                            <div className = "columnaDiseñosYColor">
                                                <div className = "diseñosResumen">
                                                    <div className = "FlexDirection-row JustifyContent-center AlignItems-center">
                                                        <p className = "bebas" style = {{fontSize: 25, margin: 0}} >{textos[idioma].TituloDiseños}</p>
                                                        <img data-tip data-for='textAyudaPosiciones' src = "/img/iconos/signo-de-interrogacion.png" className = "iconoInterrogacion"></img>
                                                        {/*
                                                        <ReactTooltip id='textAyudaPosiciones' place="top" type="dark" effect="float">
                                                            <p>
                                                                {textos[idioma].AyudaPosicionesSeleccionadas}
                                                            </p>
                                                        </ReactTooltip>
                                                        */}
                                                    </div>
                                                    {
                                                            diseñosPosiciones.map((diseño, index) =>(
                                                                    (diseño.DiseñoImagen !== null) ?
                                                                        <div style = {{marginRight: 20}} key = {diseño.DiseñoImagen.pathImage}>
                                                                            <hr className = "lineaSeparador" style = {{marginLeft: 0, marginRight: 0, marginBottom: "10px"}}/>
                                                                            <div className = "espacioTituloDiseño">
                                                                                <p className = "bebas tituloDiseño" >{productoDetalle.Categoria.NombresPosiciones[index]["Nombre"+idioma]}</p>
                                                                            </div>
                                                                            <div className = "helvetica">
                                                                                    <div className = "elementoListaDiseños">
                                                                                        <p className = "helveticaBold">{textos[idioma].Imagen}</p>
                                                                                        {
                                                                                            (diseño.DiseñoImagen.Tipo === "image") ? 
                                                                                                <img src = {diseño.DiseñoImagen.pathImage} className = "imagenDiseñoPrevisualizacion" alt = "Design"></img>
                                                                                                :
                                                                                                <div className ="documentoPDF" id = "imagenPdf">
                                                                                                    <Document file={diseño.DiseñoImagen.pathImage}> <Page pageNumber={1} /></Document>
                                                                                                </div>
                                                                                        }
                                                                                        <div className = "flexRowGeneral" style = {{marginTop: 10}}>
                                                                                            <p className = "helveticaBold" style = {{marginRight: 10}}>{textos[idioma].TipoArte}:</p>
                                                                                            <p className = "helvetica">{diseño.DiseñoImagen.TipoArte}</p>
                                                                                        </div>
                                                                                        <div className = "listaColoresDropdown">
                                                                                            <p className = "helveticaBold">{textos[idioma].Colores}: </p>
                                                                                            {
                                                                                                (diseño.DiseñoImagen.Colores != null) ?
                                                                                                    diseño.DiseñoImagen.Colores.map((color) => (
                                                                                                        <div className = "cuadroColorImagenDropdown" style = {{backgroundColor: color}}></div>
                                                                                                    ))
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                        <div className = "flexRowGeneral">
                                                                                            <p className = "helveticaBold" style = {{marginRight: 10}}>{textos[idioma].Tamaño}: </p>
                                                                                            <p className = "helvetica">{diseño.DiseñoImagen.Tamaño}"</p>
                                                                                        </div>
                                                                                    </div>
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                    null
                                                            ))
                                                    }
                                                    <hr className = "lineaSeparador" style = {{marginLeft: 0, marginRight: 0, marginBottom: "10px"}}/>
                                                </div> 
                                                <div className = "mitadCP">
                                                    <img src = {"/img/productos/" + imagenActual}  className = "imagenHerramientaDiseño" alt = {productoDetalle.Referencia}></img>
                                                    <div className = "listaColoresDetalle">
                                                        {
                                                            (productoDetalle.Variantes.length > 4) ? 
                                                                <img src = "/img/iconos/flecha-izquierda.png" className = "flechaColor" alt = "Flecha-Izquierda" onClick = {() => movimientoColor(-1)}></img>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            productoDetalle.Variantes.map((variante, index) => (
                                                                (index >= colorInicialState &&  index < colorInicialState+4) ? 
                                                                    <div className = {"cuadroColor " + (imagenActual === productoDetalle.Referencia + "-" + variante.Color._id + (variante.ColorSecundario ?  "-" + variante.ColorSecundario._id : "") +".jpg" ? "bordeAdicional" : null)} 
                                                                        style = {color(variante)} 
                                                                        onClick = {() => cambioColor(variante)}></div>
                                                                    :
                                                                    null
                                                            ))
                                                        }
                                                        {
                                                            (productoDetalle.Variantes.length > 4) ? 
                                                                <img src = "/img/iconos/flecha-derecha.png" className = "flechaColor" alt = "Flecha-Derecha" onClick = {() => movimientoColor(1)}></img>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <img data-tip data-for='textColores' src = "/img/iconos/signo-de-interrogacion.png" className = "iconoInterrogacion"></img>
                                                    {/*
                                                    <ReactTooltip id='textColores' place="top" type="dark" effect="float">
                                                        <p>
                                                            {textos[idioma].AyudaColores}
                                                        </p>
                                                    </ReactTooltip>
                                                    */}
                                                </div>
                                            </div> 
                                            <div className = "columnaTallas">
                                                <p className = "bebas" style = {{fontSize: 25}}>{textos[idioma].Tallas}</p>
                                                <div className = "panelTallas">
                                                    {
                                                        productoDetalle.Variantes[0].Tallas.map((tallaInfo) =>(
                                                            <div className = "inputTalla">
                                                                <p style = {{marginBottom: 0}} className = "bebas">{tallaInfo.Talla}</p>
                                                                <Input type="text" name="texto"  className= "inputCantidad" style = {{margin: 0}}  onChange = {(e) => modificarTallaState({...tallasState, [tallaInfo.Talla]: parseInt(e.target.value)})} placeholder="0"/>
                                                                <img src = "/img/iconos/mas.png" className = "iconoMas" alt = "More"></img>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={toggleVistaPrevia} className = "helveticaBold" style = {{fontSize: 20}}>{textos[idioma].Atras}</Button>
                                    <Button color="secondary" className = "helveticaBold" style = {{fontSize: 20}} onClick = {() => añadirAlCarrito()}>{textos[idioma].AñadirCarrito}</Button>
                                    <img data-tip data-for='textBotonCarrito' src = "/img/iconos/signo-de-interrogacion.png" className = "iconoInterrogacion"></img>
                                    {/*
                                    <ReactTooltip id='textBotonCarrito' place="top" type="dark" effect="float">
                                        <p>
                                            {textos[idioma].AyudaBotonCarrito}
                                        </p>
                                    </ReactTooltip>
                                    */}
                                </ModalFooter>
                            </Modal>

                            <Modal isOpen={modalAviso} toggle={toggleAviso} size = "lg">
                                <ModalHeader>{textos[idioma].Aviso}</ModalHeader>
                                <ModalBody>
                                    <p className = "helvetica">{textoModal}</p>
                                </ModalBody>
                                <ModalFooter >
                                    {
                                        (textoModal === textos[idioma].AñadidoCorrectamente) ?
                                            <div className = "modalFooter">
                                                <Button color="secondary" onClick={() => cerrarModalAviso()}>{textos[idioma].ContinuarAñadiendo}</Button>
                                                <Link to= "/Pedido">
                                                    <Button color="secondary">{textos[idioma].IrCarrito}</Button>
                                                </Link>
                                            </div>   
                                            :
                                            <div className = "modalFooter">
                                                <Button color="secondary" onClick={() => cerrarModalAviso()}>{textos[idioma].Cerrar}</Button>
                                            </div>      
                                    }
                                </ModalFooter>
                            </Modal>
                            <Modal isOpen={modalAvisoPosicionSinGuardar} toggle={toggleAvisoPosicionSinGuardar} size = "lg">
                                <ModalHeader>{textos[idioma].Aviso}</ModalHeader>
                                <ModalBody>
                                    <p className = "helvetica">{textos[idioma].DiseñoNoGuardado}</p>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={() => eliminarDiseño()}>{textos[idioma].Continuar}</Button>
                                    <Button color="secondary" onClick={() => setModalAvisoPosicionSinGuardar(false)}>{textos[idioma].Cerrar}</Button>
                                </ModalFooter>
                            </Modal>
                            <Footer/>
                        </div>    
                        :
                        null
                }
            </div>
    );
}
 
export default HerramientaDiseño;