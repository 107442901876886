import React , {useReducer, useEffect} from 'react';

import GeneralContext from './GeneralContext';
import GeneralReducer from './GeneralReducer';

import { 
    MOSTRAR_SUBCATEGORIAS,
    MODIFICAR_CATEGORIAS,
    MODIFICAR_SUBCATEGORIAS,
    MODIFICAR_COLORES,
    MODIFICAR_PRODUCTOS,
    MODIFICAR_PRODUCTO_DETALLE,
    MOSTRAR_BARRA_PRODUCTO,
    MODIFICAR_CARRITO,
    MODIFICAR_IDIOMA,
    MODIFICAR_PANTALLA_GRIS,
    MODIFICAR_USUARIO,
    MODIFICAR_DISEÑOS} from '../types'

const GeneralState = props => {
    let DEBUG = false;
    const initialState = {
        isShown: false,
        categoriasState: [],
        subcategoriasState: {Categoria_id: "", CategoriaEN: "", CategoriaES: "", Subcategorias: []},
        coloresState: [],
        productosState: [],
        productoDetalle: null,
        barraBuscar: true,
        carrito: [],
        urlBack: (DEBUG) ? "http://localhost:8080/" : "https://muchomercho-back.herokuapp.com/",
        idioma: "EN",
        pantallaGris: false,
        diseños: [],
        usuario : {
            "Nombres": '',
            "Apellidos": '',
            "Correo": '',
            "Celular": '',
            "Departamento_Ciudad": '',
            "CodigoPostal": "",
            "Direccion": '',
            "Barrio": '',
            "Validacion": false
        }
    }

    //dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(GeneralReducer, initialState);

    //Serie de funciones 
    const setIsShown = estado  => {
        dispatch({
            type: MOSTRAR_SUBCATEGORIAS,
            payload: estado
        })
    }

    const setDiseños = diseños  => {
        dispatch({
            type: MODIFICAR_DISEÑOS,
            payload: diseños
        })
    }

    const modificarCategorias = categorias  => {
        dispatch({
            type: MODIFICAR_CATEGORIAS,
            payload: categorias
        })
    }             

    const modificarSubcategorias = subcategorias  => {
        dispatch({
            type: MODIFICAR_SUBCATEGORIAS,
            payload: subcategorias
        })
    }

    const modificarColores = colores  => {
        dispatch({
            type: MODIFICAR_COLORES,
            payload: colores
        })
    }

    const modificarProductos = productos  => {
        dispatch({
            type: MODIFICAR_PRODUCTOS,
            payload: productos
        })
    }

    const modificarProductoDetalle = producto  => {
        dispatch({
            type: MODIFICAR_PRODUCTO_DETALLE,
            payload: producto
        })
    }

    const mostrarBarraBuscar = estado  => {
        dispatch({
            type: MOSTRAR_BARRA_PRODUCTO,
            payload: estado
        })
    }

    const modificarCarrito = newCarrito  => {
        dispatch({
            type: MODIFICAR_CARRITO,
            payload: newCarrito
        })
    }

    const modificarIdioma = newIdioma  => {
        dispatch({
            type: MODIFICAR_IDIOMA,
            payload: newIdioma
        })
    }

    const modificarPantallaGris = estado  => {
        dispatch({
            type: MODIFICAR_PANTALLA_GRIS,
            payload: estado
        })
    }

    const modificarUsuario = usuario  => {
        dispatch({
            type: MODIFICAR_USUARIO,
            payload: usuario
        })
    }


    useEffect(()=>{
        let carrActual = sessionStorage.getItem("Carrito");
        if(carrActual && state.carrito.length === 0){
            let carr = JSON.parse(carrActual);
            if(carr.length !== 0)
                modificarCarrito(carr);
        }
    })
    
    return (
        <GeneralContext.Provider
            value ={{ 
                isShown: state.isShown,
                categoriasState: state.categoriasState,
                subcategoriasState: state.subcategoriasState, 
                coloresState: state.coloresState,
                productosState: state.productosState,
                productoDetalle: state.productoDetalle,
                barraBuscar: state.barraBuscar,
                carrito: state.carrito,
                urlBack: state.urlBack,
                idioma: state.idioma,
                pantallaGris: state.pantallaGris,
                usuario: state.usuario,
                diseños: state.diseños,
                setIsShown,
                setDiseños,
                modificarCategorias,
                modificarSubcategorias,
                modificarColores,
                modificarProductos,
                modificarProductoDetalle,
                mostrarBarraBuscar,
                modificarCarrito,
                modificarIdioma,
                modificarPantallaGris,
                modificarUsuario
            }}
        >
            {props.children}
        </GeneralContext.Provider>
    )
}

export default GeneralState;