import React ,{ useContext} from 'react';
import GeneralContext from '../../context/GeneralContext'


import './static/css/Footer.css'
const Footer = () => {

    const GC = useContext(GeneralContext);
    const { categoriasState , idioma} = GC;

    const textos = {
      ES: {
        Categorias: "Categorias",
        AccesosRapidos: "Accesos Rapidos",
        AcercaDe: "Acerca de nosotros",
        Contactanos: "Contactanos",
        Contribuir: "Contribuir",
        Politicas: "Politicas de privacidad",
        Derechos: " 2021 Todos los derechos reservados"
      },
      EN: {
        Categorias: "Categories",
        AccesosRapidos: "Quick Access",
        AcercaDe: "Acerca de nosotros",
        Contactanos: "Contact us",
        Contribuir: "Contribute",
        Politicas: "Privacy policies",
        Derechos: " 2021 All rights reserved"
      }
    }

    //retorna el string recibido con la primera letra mayuscula.
    const capitalizarPalabras = ( val ) => {
      var minuscula = val.toLowerCase();
      return (val !== "") ? minuscula[0].toUpperCase() + minuscula.slice(1) : "";  
    }
    return (   
        <footer className = "site-footer footer">
          <div className = "container">
            <div className = "row">
              <div className = "col-xs-6 col-md-3">
                <h6>{textos[idioma].Categorias}</h6>
                <ul className = "footer-links">
                    {
                        categoriasState.map((categoria ,index) => (
                            (index < 6) ?
                                <li key = {categoria["Categoria"+idioma]}>{capitalizarPalabras(categoria["Categoria"+idioma])}</li>
                                : null
                        ))
                        
                    }
                  
                </ul>
              </div>
    
              <div className = "col-xs-6 col-md-3">
                <h6>{textos[idioma].AccesosRapidos}</h6>
                <ul className = "footer-links">
                  <li>{textos[idioma].AcercaDe}</li>
                  <li>{textos[idioma].Contactanos}</li>
                  <li>{textos[idioma].Contribuir}</li>
                  <li>{textos[idioma].Politicas}</li>
                </ul>
              </div>
            </div>
            <hr/>
          </div>
          <div className = "container">
            <div className = "row">
              <div className = "col-md-8 col-sm-6 col-xs-12">
                <p className = "copyright-text" style = {{fontSize: "18px"}}>Copyright &copy; {textos[idioma].Derechos}.
                </p>
              </div>
    
              <div className = "col-md-4 col-sm-6 col-xs-12">
                <ul className = "social-icons">
                  <li><a href = "https://www.facebook.com/muchomercho.northamerica"><img src = "/img/iconos/facebook.svg" className = "imagenRedSocial" alt = "Facebook"></img></a></li>
                  <li><img src = "/img/iconos/pinterest.png" className = "imagenRedSocial" alt = "Pinterest"></img></li>
                </ul>
              </div>
             
            </div>
          </div>
    </footer> 
    );
}
 
export default Footer;