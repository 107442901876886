import React , {useContext, useEffect, useState} from 'react';
import { useParams } from "react-router";
import GeneralContext from '../../context/GeneralContext'
import Footer from '.././Footer/Footer'
import axios from 'axios'
import MediaQuery from 'react-responsive'

//Importaciones internas
import './static/css/ListaProductos.css'
import Producto from './Producto'

const ListaProductos = ({loadingState, modificarLoadingState}) => {
     let { idCategoria, idSubcategoria } = useParams();

     const GC = useContext(GeneralContext);
     const { categoriasState, coloresState, modificarColores, productosState, modificarProductos , urlBack, barraBuscar, mostrarBarraBuscar, idioma} = GC;

     const [consultadoState, modificarConsultadoState] = useState(false);
     

     const textos = {
          ES: {
               AunNo: "Aun no tenemos productos en esta categoria, pero",
               Proximamente: "Próximamente...",
               Categorias: "Categorias",
               Colores: "Colores",
               mensajeCargando: "Cargando..."
          },
          EN: {
               AunNo: "We still do not have products in this category, but",
               Proximamente: "Coming soon...",
               Categorias: "Categories",
               Colores: "Colors",
               mensajeCargando: "Loading..."
          }
     }
     //retorna el string recibido con la primera letra mayuscula.
     const capitalizarPalabras = ( val ) => {
          var minuscula = val.toLowerCase();
          return (val !== "") ? minuscula[0].toUpperCase() + minuscula.slice(1) : "";  
     }


     
     const obtenerColores = async () =>{
          modificarLoadingState({estado: true, texto: textos[idioma].mensajeCargando})
          await axios.post(urlBack + "Api/Color/GetColores",{
              "data": [
                  {}
              ]}
              ).then(response => {
                  if(!response.data[0].error){
                    modificarColores(response.data[0].data);
                  }   
                  else{
                      console.log("Hubo un error al consultar", response.data[0])
                  };
                  modificarLoadingState({estado: false, texto: ""})
              }).catch(function(err) {
                  console.log("Hubo un error al consultar", err)
                  modificarLoadingState({estado: false, texto: ""})
              });
      }

      const obtenerProductos = async () =>{
          modificarLoadingState({estado: true, texto: textos[idioma].mensajeCargando})
          await axios.post(urlBack + "Api/Producto/GetProductos",{
               "data": {
                    "Ids":[
                         
                    ],
                    "Categorias":[
                         {
                              "Categoria": idCategoria
                         }
                    ],
                    "Subcategorias":[
                        {
                            "Subcategoria": idSubcategoria
                        }
                    ],
                    "Generos":[
                        
                    ],
                    "Marcas":[
                        
                    ],
                    "Tallas":[
                        
                    ],
                    "Colores":[
                        
                    ],
                    "ParametroOrdenamiento": "Categoria"
                }
               }).then(response => {
                    modificarProductos(response.data.data);
                    modificarConsultadoState(true);
                    modificarLoadingState({estado: false, texto: ""})
              }).catch(function(err) {
                  console.log("Hubo un error al consultar", err)
                    modificarLoadingState({estado: false, texto: ""})
              });
      }

     useEffect(()=>{
          if(!barraBuscar)
               mostrarBarraBuscar(true);
          if(productosState.length !== 0) {
               if(productosState[0].Subcategoria._id !== idSubcategoria)
                    obtenerProductos();
          }
          if(productosState.length === 0 && !consultadoState){
               obtenerProductos();
          }
     }, [productosState, coloresState, idSubcategoria, consultadoState, barraBuscar, idioma])
     return ( 
          <div id = "ListaProductos" className = "topLista">
               <div className = "espacioListaProductos"> 
                    <div className = "espacioProductos">
                         {
                              (productosState.length !== 0) ?
                                   <div className = "Productos">
                                        <p className = "tituloLista bebas">{productosState[0].Categoria["Categoria"+idioma] + " / " + productosState[0].Subcategoria["Subcategoria"+idioma]}</p>
                                        <div className = "matrizProductos">
                                             {
                                                  productosState.map((producto) => (
                                                       <Producto 
                                                            key = {producto._id} 
                                                            producto = {producto}
                                                       />
                                                  ))
                                             }
                                        </div>
                                   </div>
                                   
                                   :
                                   <div className = "textoProximamente">
                                        <p className = "bebas" style = {{"fontSize": 40}}>{textos[idioma].AunNo}</p>
                                        <p className = "bebas" style = {{"fontSize": 60}}>{textos[idioma].Proximamente}</p>
                                   </div>
                         }
                         
                    </div>
                    <MediaQuery minWidth={1224}>         
                         <div className = "Filtros">
                              <div className ="vertical-line"></div>
                              <div className = "seleccionCategorias">
                                   <p className = "tituloFiltro helvetica">{textos[idioma].Categorias}</p>
                                   {
                                        categoriasState.map((categoria) => (
                                             <div className = "categoriaSeleccionable">
                                                  <label className="container"><p className = "tituloCategoriaSeleccionable">{capitalizarPalabras(categoria["Categoria"+idioma])}</p>
                                                       <input type="checkbox"/>
                                                       <span className="checkmark"></span>
                                                  </label>
                                             </div>
                                        ))
                                   }
                                   {
                                        /*
                                        <hr className = "lineaFiltro"/>
                                        <p className = "tituloFiltro helvetica">{textos[idioma].Colores}</p>
                                        {
                                             coloresState.map((color) => (
                                                  <div className = "categoriaSeleccionable">
                                                       <label className="container"><p className = "tituloCategoriaSeleccionable">{capitalizarPalabras(color["Color"+idioma])}</p>
                                                            <input type="checkbox"/>
                                                            <span className="checkmark"></span>
                                                       </label>
                                                  </div>
                                             ))
                                        }
                                        */
                                   }
                                   <hr className = "lineaFiltro"/>
                              </div>
                         </div>
                    </MediaQuery>
               </div>
               <Footer/>
          </div>
     );
}
 
export default ListaProductos;