import React, {useContext, useEffect, useState} from 'react';
import { useParams } from "react-router";
import { Link } from 'react-router-dom';
import Footer from '.././Footer/Footer'
import GeneralContext from '../../context/GeneralContext'
import axios from 'axios'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './static/css/DetalleProducto.css'

const DetalleProducto = ({loadingState, modificarLoadingState}) => {
    const GC = useContext(GeneralContext);
    const { productoDetalle, modificarProductoDetalle, mostrarBarraBuscar, urlBack, idioma} = GC;

    const [imagenActual, modificarImagenActual] = useState((productoDetalle !== null) ? productoDetalle.Referencia + "-" +productoDetalle.Variantes[0].Color._id +  (productoDetalle.Variantes[0].ColorSecundario ?  "-" + productoDetalle.Variantes[0].ColorSecundario._id : "") +".jpg" : null);
    const [colorInicialState, modificarColorInicialState] = useState(0);
    

    const [textoModal, modificarTextoModal] = useState("");

    const [modalAviso, setModalAviso] = useState(false);
    const toggleAviso = () => setModalAviso(!modalAviso);

    let { idProducto } = useParams();

    const textos = {
        ES: {
            Colores: "Colores",
            ListaTallas: "Lista de tallas",
            BotonDiseño: "Realizar diseño",
            mensajeCargando: "Cargando..."
        },
        EN: {
            Colores: "Colors",
            ListaTallas: "Size list",
            BotonDiseño: "Make design",
            mensajeCargando: "Loading..."
        }
    }

    const movimientoColor = (movimiento) => {
        if(colorInicialState+4 < productoDetalle.Variantes.length && movimiento === 1)
            modificarColorInicialState(colorInicialState + movimiento);
        else if(colorInicialState > 0 && movimiento === -1)
            modificarColorInicialState(colorInicialState-1)
    }

    const cambioColor = (variante) => {
        modificarImagenActual(productoDetalle.Referencia + "-" + variante.Color._id + (variante.ColorSecundario ?  "-" + variante.ColorSecundario._id : "") +".jpg")
    }

    const cerrarModalAviso = () =>{
        setModalAviso(false);
        modificarTextoModal("");
    }

    const obtenerProductoDetalle =   React.useCallback(async () =>{
        modificarLoadingState({estado: true, texto: textos[idioma].mensajeCargando})
        await axios.post(urlBack + "Api/Producto/GetProductos",{
            "data": {
                "Ids":[
                    {
                        "_id": idProducto
                    }
                ],
                "Categorias":[
        
                ],
                "Subcategorias":[
                    
                ],
                "Generos":[
                    
                ],
                "Marcas":[
                    
                ],
                "Tallas":[
                    
                ],
                "Colores":[
                    
                ],
                "ParametroOrdenamiento": "Categoria"
            }
        }).then(response => {
            modificarProductoDetalle(response.data.data[0]);
            modificarLoadingState({estado: false, texto: ""})
        }).catch(function(err) {
            console.log("Hubo un error al consultar", err)
            modificarLoadingState({estado: false, texto: ""})
        });
    }, [idProducto, modificarProductoDetalle, urlBack])

    const color = (variante) => {
        return (variante.ColorSecundario)  ? {"background": "linear-gradient(to right,"+ variante.Color.Hexadecimal+ "  0%,"+ variante.Color.Hexadecimal+ " 50%, "+ variante.ColorSecundario.Hexadecimal+ " 50%, "+ variante.ColorSecundario.Hexadecimal+ " 100%)"} : {"backgroundColor": variante.Color.Hexadecimal}
    }

    useEffect(()=>{
        if(productoDetalle === null)
             obtenerProductoDetalle();
        if(imagenActual === null && productoDetalle !== null)
            modificarImagenActual(productoDetalle.Referencia + "-" +productoDetalle.Variantes[0].Color._id + ".jpg")
    }, [productoDetalle, obtenerProductoDetalle, imagenActual])

    return ( 
        <div>
            {   
                (productoDetalle !== null) ?
                <div className = "espacioDetalleProducto topDetalle">
                    <div className = "detalleProducto">
                        <div className = "espacioImagenProducto">
                            <p className = "tituloDetalleProducto helvetica">{productoDetalle.Referencia}</p>
                            <img src = {"/img/productos/" + imagenActual} className = "imagenProductoDetalle" alt = {productoDetalle.Referencia}></img>
                            <div className = "listaImagenes">
                            {
                                productoDetalle.Variantes.map((variante) => (
                                    <img    className = {"cuadroImagen " + (productoDetalle.Referencia + "-" + variante.Color._id + (variante.ColorSecundario ?  "-" + variante.ColorSecundario._id : "") + ".jpg" === imagenActual ? "cuadroSeleccion" : null ) } 
                                            src = {"/img/productos/" + productoDetalle.Referencia + "-" + variante.Color._id + (variante.ColorSecundario ?  "-" + variante.ColorSecundario._id : "") + ".jpg"}
                                            onClick = {() => modificarImagenActual(productoDetalle.Referencia + "-" + variante.Color._id + (variante.ColorSecundario ?  "-" + variante.ColorSecundario._id : "") + ".jpg")}
                                            alt = {productoDetalle.Referencia}></img>
                                ))
                            }
                            </div>
                        </div>
                        <div className = "informacionDetalleProducto">
                            <p className = "tituloDetalleProducto helvetica">{productoDetalle.Nombre}</p>
                            {
                                productoDetalle["Descripcion"+idioma].map((info) => (
                                    <div className = "tiraInfo">
                                        <img src = "/img/iconos/punto.png" className = "puntoDescripcion" alt = "lista"></img>
                                        <p className = "infoDescripcion helvetica">{info}</p>

                                    </div>
                                ))
                            }
                            <p className = "tituloColor helvetica"><bold><strong>{textos[idioma].Colores}:</strong></bold></p>
                            <div className = "listaColoresDescripcion" >
                                {
                                    (productoDetalle.Variantes.length > 4) ? 
                                        <img src = "/img/iconos/flecha-izquierda.png" className = "flechaColor" alt = "Flecha-Izquierda" onClick = {() => movimientoColor(-1)}></img>
                                        :
                                        null
                                }
                                {
                                    productoDetalle.Variantes.map((variante, index) => (
                                        (index >= colorInicialState &&  index < colorInicialState+4) ? 
                                            <div className = {"cuadroColor " + (imagenActual === productoDetalle.Referencia + "-" + variante.Color._id + (variante.ColorSecundario ?  "-" + variante.ColorSecundario._id : "") +".jpg" ? "bordeAdicional" : null)} 
                                                 style = {color(variante)} 
                                                 onClick = {() => cambioColor(variante)}></div>
                                            :
                                            null
                                    ))
                                }
                                {
                                    (productoDetalle.Variantes.length > 4) ? 
                                        <img src = "/img/iconos/flecha-derecha.png" className = "flechaColor" alt = "Flecha-Derecha" onClick = {() => movimientoColor(1)}></img>
                                        :
                                        null
                                }
                            </div>
                            <div className = "listaTallasDescripcion" >
                                <p className = "tituloColor helvetica" style = {{marginRight: "10px"}}><bold><strong>{textos[idioma].ListaTallas}</strong></bold></p>
                                {
                                    productoDetalle.Variantes[0].Tallas.map((talla, index) => (
                                        <p className = "talla helvetica">{talla.Talla + (index === productoDetalle.Variantes[0].Tallas.length-1 ? "" : ",")}</p>
                                    ))
                                }
                            </div>
                            <div className = "listaBotonesDetalleProducto">
                                <Link to = {"/HerramientaDiseño/"+idProducto} onClick = {() => mostrarBarraBuscar(false)} className ="helvetica" >
                                    <Button color="secondary" className = "botonDetalleProducto helveticaBold">{textos[idioma].BotonDiseño}</Button>
                                </Link>
                            </div>
                        </div>
                        <Modal isOpen={modalAviso} toggle={toggleAviso} size = "lg">
                            <ModalHeader>Aviso</ModalHeader>
                            <ModalBody>
                                <p className = "helvetica">{textoModal}</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={() => cerrarModalAviso()}>Cerrar</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                    <Footer/>
                </div>
                :
                null
            }
            
        </div>
     );
}
 
export default DetalleProducto;