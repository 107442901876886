export const MOSTRAR_SUBCATEGORIAS = 'MOSTRAR_SUBCATEGORIAS'
export const MODIFICAR_CATEGORIAS = 'MODIFICAR_CATEGORIAS'
export const MODIFICAR_SUBCATEGORIAS = 'MODIFICAR_SUBCATEGORIAS'
export const MODIFICAR_COLORES = 'MODIFICAR_COLORES'
export const MODIFICAR_PRODUCTOS = 'MODIFICAR_PRODUCTOS'
export const MODIFICAR_PRODUCTO_DETALLE = 'MODIFICAR_PRODUCTO_DETALLE'
export const MOSTRAR_BARRA_PRODUCTO = 'MOSTRAR_BARRA_PRODUCTO'
export const MODIFICAR_CARRITO = 'MODIFICAR_CARRITO'
export const MODIFICAR_IDIOMA = 'MODIFICAR_IDIOMA'
export const MODIFICAR_PANTALLA_GRIS = 'MODIFICAR_PANTALLA_GRIS'
export const MODIFICAR_USUARIO = 'MODIFICAR_USUARIO'
export const MODIFICAR_LOADING = 'MODIFICAR_LOADING'
export const MODIFICAR_DISEÑOS = 'MODIFICAR_DISEÑOS'
