import React, {useEffect, useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'
import MediaQuery from 'react-responsive'

//Importaciones internas
import './static/css/Header.css'
import GeneralContext from '../../context/GeneralContext'

const Header = ({loadingState, modificarLoadingState}) => {
    
    const GC = useContext(GeneralContext);
    const { isShown, setIsShown , categoriasState, modificarCategorias, subcategoriasState, modificarSubcategorias, barraBuscar, urlBack, modificarProductos, idioma, modificarIdioma, pantallaGris, modificarPantallaGris, carrito} = GC;

    const [menuIdioma, mostraMenuIdioma] = useState(false);
    const [posicionMenuState, modificarPosicionMenuState] = useState("-100vw");
    const [cantidadesProd, setCantidadesProd] = useState(null)

    const textos = {
        ES: {
            textoEnvio: "Envios gratis",
            Contacto: "Contactanos",
            Buscar: "Buscar",
            QueBuscas: "¿Que estás buscando?",
            Sesion: "Iniciar sesion",
            Carrito: "Carrito",
            Sesion: "Perfil",
            Categorias: "Categorias",
            Subcategorias: "Subcategorias",
            mensajeCargando: "Cargando...",
        },
        EN: {
            textoEnvio: "Free Delivery",
            Contacto: "Contact us",
            Buscar: "Search",
            Sesion: "Log in",
            QueBuscas: "What are you looking for?",
            Carrito: "Shopping cart",
            Sesion: "Profile",
            Categorias: "Categories",
            Subcategorias: "Subcategories",
            mensajeCargando: "Loading...",
        }
    }

    const obtenerProductos = async (idCategoria, idSubcategoria) =>{
        modificarLoadingState({estado: true, texto: textos[idioma].mensajeCargando})
        await axios.post(urlBack + "Api/Producto/GetProductos",{
             "data": {
                  "Ids":[
                       
                  ],
                  "Categorias":[
                       {
                            "Categoria": idCategoria
                       }
                  ],
                  "Subcategorias":[
                      {
                          "Subcategoria": idSubcategoria
                      }
                  ],
                  "Generos":[
                      
                  ],
                  "Marcas":[
                      
                  ],
                  "Tallas":[
                      
                  ],
                  "Colores":[
                      
                  ],
                  "ParametroOrdenamiento": "Categoria"
            }
        }).then(response => {
            console.log("Productos: ", response.data.data)
            modificarProductos(response.data.data);
            modificarLoadingState({estado: false, texto: ""})
        }).catch(function(err) {
            console.log("Hubo un error al consultar", err)
            modificarLoadingState({estado: false, texto: ""})
        });
    }

    const contarCantidades = async () =>{
        await axios.get(urlBack + "Api/Producto/ConteoProductos",{
             "data": [
                {}
             ]
        }).then(response => {
            setCantidadesProd(response.data);
        }).catch(function(err) {
            console.log("Hubo un error al consultar", err)
        });
    }


    const obtenerCategorias = async () =>{
        modificarLoadingState({estado: true, texto: textos[idioma].mensajeCargando})
        await axios.post(urlBack + "Api/Categoria/GetCategorias",{
            "data": [
                {}
            ]}
        ).then(response => {
            console.log(response)
            if(!response.data[0].error){
                modificarCategorias(response.data[0].data);
            }   
            else{
                console.log("Hubo un error al consultar", response.data[0])
            };
            modificarLoadingState({estado: false, texto: ""})
        }).catch(function(err) {
            console.log("Hubo un error al consultar", err)
            modificarLoadingState({estado: false, texto: ""})
        });
    }

    const hoverCategoria = async (categoria, subcategorias) =>{
        modificarSubcategorias({Categoria_id: categoria._id, CategoriaEN: categoria.CategoriaEN, CategoriaES: categoria.CategoriaES, Subcategorias: subcategorias});
        setIsShown(true);
        modificarPantallaGris(true);
    }

    //retorna el string recibido con la primera letra mayuscula.
    const capitalizarPalabras = ( val ) => {
        var minuscula = val.toLowerCase();
        return (val !== "") ? minuscula[0].toUpperCase() + minuscula.slice(1) : "";  
    }

    const clickAtras = (cerrar) =>{
        setIsShown(false);
        modificarSubcategorias({Categoria_id: "", CategoriaEN: "", CategoriaES: "", Subcategorias: []});
        if(cerrar){
            modificarPosicionMenuState("-100vw");
            modificarPantallaGris(false);
        }
    }

    const showYPantalla = (show, pantalla, posicion) =>{
        setIsShown(show);
        modificarPantallaGris(pantalla);
        modificarPosicionMenuState(posicion);
    }

    const calculoCantidad = (producto) =>{
        let cantidadFinal = 0;
        for(let talla in producto.Tallas)
            cantidadFinal += producto.Tallas[talla];
        return cantidadFinal;
    }

    const calcularCantidadCarrito = () =>{
        let cantidad = 0;
        for(let car in carrito){
            cantidad += calculoCantidad(carrito[car]);
        }

        return cantidad;
    }

    const cantidadDigitosCarrito = () =>{
        let cant = calcularCantidadCarrito();

        return cant >= 100 ? "7px" : cant >= 10 ? "10px" : "15px";
    }

    useEffect(()=>{
        if(categoriasState.length === 0)
            obtenerCategorias();
        if(!isShown && subcategoriasState.Subcategorias.length !== 0)
            modificarSubcategorias({Categoria_id: "", CategoriaEN: "", CategoriaES: "", Subcategorias: []});
        //if(cantidadesProd === null)
        //    contarCantidades()
    }, [isShown, subcategoriasState, cantidadesProd, categoriasState])

    return ( 
        <header className ="header">
            { pantallaGris ? <div className = "Sombra"></div> : null}
            <MediaQuery minWidth={1224}>                              
                <div className = "barraInformativa">
                    <div className = "datosBarra">
                        <div className = "ElementosBarra">
                            <div className = "enviosGratis">
                                <img src = "/img/iconos/entrega.png" className = "imagenEnvioBarra" alt = {textos[idioma].textoEnvio}></img>
                                <p className = "textoBarra helveticaBold">{textos[idioma].textoEnvio}</p>
                            </div>
                            <div className = "ContactanosYFAQ">
                                <div className = "contactanosHeader">
                                    <img src = "/img/iconos/telefono.png" className = "imagenBarra" alt = {textos[idioma].Contacto}></img>
                                    <p className = "textoBarra helveticaBold">{textos[idioma].Contacto}</p>
                                </div>
                                <div className = "FAQHeader">
                                    <img src = "/img/iconos/FAQ.png" className = "imagenBarra" alt = "FAQ"></img>
                                    <p className = "textoBarra helveticaBold">FAQ</p>
                                </div>
                                <div className = "espacioIdioma">
                                    <div className = "botonIdioma" onClick={() => mostraMenuIdioma(!menuIdioma)}>
                                        <img src = {"/img/iconos/"+idioma+".png"} className = "banderita" alt = "Language"></img>
                                        <img src = {"/img/iconos/flecha-abajo.png"} className = "flecha" alt = "Arrow"></img>
                                    </div>
                                    {
                                        menuIdioma ?
                                        <div className = "menuIdioma" onMouseLeave = {() => mostraMenuIdioma(false)} onClick={() => mostraMenuIdioma(false)}>
                                            <div className = "filaMenuIdioma" onClick = {() => modificarIdioma("ES")}>
                                                <img src = {"/img/iconos/ES.png"} className = "banderita" alt = "Idioma"></img>
                                                <p className = "bebas textoIdioma">ES</p>
                                            </div>
                                            <div className = "filaMenuIdioma" onClick = {() => modificarIdioma("EN")}>
                                                <img src = {"/img/iconos/EN.png"} className = "banderita" alt = "Idioma"></img>
                                                <p className = "bebas textoIdioma">EN</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = "barraLogo"> 
                    <Link to= "/" className = "espacioLogo">
                        <img src = "/img/header/logo.jpg" className = "logoMuchoMercho" alt = "MuchoMercho"></img>
                    </Link>
                    <div className="field" id="searchform">
                        <input type="text" id="searchterm" placeholder={textos[idioma].QueBuscas}/>
                        <button type="button" id="search">{textos[idioma].Buscar}</button>
                    </div>
                    <div className = "filaBotonesHeader">
                        <Link to= "/Pedido" className = "carritoHeader">
                            <div style = {{position: "relative", width: "40px"}}>
                                <img src = "/img/header/carrito-negro.png" className = "iconoCarrito" alt = "Carrito"></img>
                                <p className = "helveticaBold cantidadCarrito" style = {{left: cantidadDigitosCarrito()}}>{calcularCantidadCarrito()}</p>
                            </div>
                            <p className = "textoCarrito helveticaBold">{textos[idioma].Carrito}</p>
                        </Link>
                        <Link to= "/" className = "carritoHeader">
                            <div style = {{position: "relative", width: "40px"}}>
                                <img src = "/img/iconos/user.png" className = "iconoCarrito" alt = "Carrito"></img>
                            </div>
                            <p className = "textoCarrito helveticaBold">{textos[idioma].Sesion}</p>
                        </Link>
                    </div>
                </div>
                <div className = "espacioCategorias">
                    <ul className = "barraCategorias helvetica">
                        {
                            categoriasState.map((categoria) => (
                                <li key = {categoria._id} 
                                    className = {(subcategoriasState.Categoria_id === categoria._id) ? "categoria seleccionCategoria" : "categoria"}
                                    onMouseEnter={() => hoverCategoria(categoria, categoria.Subcategorias)} 
                                    onMouseLeave={() => showYPantalla(false, false, "0wv")}>
                                        <p  className = "tituloCategoria helveticaBold" >{capitalizarPalabras(categoria["Categoria"+idioma])}</p>
                                </li>
                            ))
                        }
                    </ul>
                    {
                        (isShown) ?
                            <ul className = "barraSubcategorias helvetica" onMouseEnter={() => setIsShown(true)} onMouseLeave={() => showYPantalla(false, false, "0wv")} onMouseOver = {() => showYPantalla(true, true, "0wv")}>
                                {
                                    //(subcategoriasState.Subcategorias.length > 0 && cantidadesProd !== null) ?
                                    (subcategoriasState.Subcategorias.length > 0 ) ?
                                        subcategoriasState.Subcategorias.map((Subcategoria) => (
                                            <li key = {Subcategoria._id} className = "Subcategoria">
                                                <Link to={"/Productos/"+subcategoriasState.Categoria_id + "/" +Subcategoria.Subcategoria._id} className = "tituloSubcategoria" onClick = {() => obtenerProductos(subcategoriasState.Categoria_id, Subcategoria.Subcategoria._id)}>
                                                    <p className = "helveticaBold subcategoriaIndividual">{capitalizarPalabras(Subcategoria.Subcategoria["Subcategoria"+idioma])}</p>
                                                </Link>
                                                {/*<p className = "helveticaBold cantidadSubcategoria">{cantidadesProd[subcategoriasState.Categoria_id][Subcategoria.Subcategoria._id]}</p>*/}
                                            </li>
                                        ))
                                        :
                                        null
                                }
                            </ul>
                            :
                            null
                    }
                </div> 
            </MediaQuery>  
            <MediaQuery maxWidth={1223}>
                <div className = "barraInformativa">
                    <div className = "datosBarra">
                        <div className = "ElementosBarra">
                            <div className = "enviosGratis">
                                <img src = "/img/iconos/entrega.png" className = "imagenEnvioBarra" alt = {textos[idioma].textoEnvio}></img>
                                <p className = "textoBarra helveticaBold">{textos[idioma].textoEnvio}</p>
                            </div>
                            <div className = "ContactanosYFAQ">
                                <div className = "contactanosHeader">
                                    <img src = "/img/iconos/telefono.png" className = "imagenBarra" alt = {textos[idioma].Contacto}></img>
                                </div>
                                <div className = "FAQHeader">
                                    <img src = "/img/iconos/FAQ.png" className = "imagenBarra" alt = "FAQ"></img>
                                </div>
                                <div className = "espacioIdioma">
                                    <div className = "botonIdioma" onClick={() => mostraMenuIdioma(!menuIdioma)}>
                                        <img src = {"/img/iconos/"+idioma+".png"} className = "banderita" alt = "Language"></img>
                                        <img src = {"/img/iconos/flecha-abajo.png"} className = "flecha" alt = "Arrow"></img>
                                    </div>
                                    {
                                        menuIdioma ?
                                        <div className = "menuIdioma" onMouseLeave = {() => mostraMenuIdioma(false)} onClick={() => mostraMenuIdioma(false)}>
                                            <div className = "filaMenuIdioma" onClick = {() => modificarIdioma("ES")}>
                                                <img src = {"/img/iconos/ES.png"} className = "banderita" alt = "Idioma"></img>
                                                <p className = "bebas textoIdioma">ES</p>
                                            </div>
                                            <div className = "filaMenuIdioma" onClick = {() => modificarIdioma("EN")}>
                                                <img src = {"/img/iconos/EN.png"} className = "banderita" alt = "Idioma"></img>
                                                <p className = "bebas textoIdioma">EN</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = "barraLogo"> 
                    <div className = "espacioMenu">
                        <img src = "/img/iconos/menu.png" className = "imagenMenu" onClick = {() => showYPantalla(false, true, "0vw")} alt = "Main"></img>
                    </div>
                    <Link to= "/" className = "espacioLogo">
                        <img src = "/img/header/logo.jpg" className = "logoMuchoMercho" alt = "MuchoMercho"></img>
                    </Link>
                    <div className = "filaBotonesHeader">
                        <img src = "/img/header/inicioSesion-negro.png" className = "iconoSesion" alt = "Sesion"></img>
                        <Link to= "/Pedido" className = "carritoHeader">
                            <img src = "/img/header/carrito-negro.png" className = "iconoCarrito" alt = "Carrito"></img>
                        </Link>
                    </div>
                </div>
                <div className = "menuHeader" style = {{left: posicionMenuState}}>
                    <div className = "espacioBotonesArriba">
                        <div className = "espacioBotonArriba" style = {{justifyContent: "flex-start"}}>
                            {
                                isShown ?
                                    <img src = "/img/iconos/flecha-atras.png" className = "botonCerrarMenu" onClick = {() => clickAtras(false)} alt = "Back"></img>
                                    :
                                    null
                            }
                        </div>
                        <div className = "espacioBotonArriba" style = {{justifyContent: "flex-end"}}>
                            <img src = "/img/iconos/boton-cerrar.png" className = "botonCerrarMenu" onClick = {() => clickAtras(true)} alt = "Close"></img>
                        </div>
                    </div>
                    <div className="field" id="searchform">
                        <input type="text" id="searchterm" placeholder={textos[idioma].QueBuscas}/>
                        <button type="button" id="search">{textos[idioma].Buscar}</button>
                    </div>
                    {
                        (!isShown) ?
                            <div className = "espacioCategoriasMenu">
                                <p className = "bebas tituloMenu" >{textos[idioma].Categorias}</p>
                                {
                                    categoriasState.map((categoria) => (
                                        <div className = "filaCategoriaMenu" onClick = {() => hoverCategoria(categoria, categoria.Subcategorias)}>
                                            <p key = {categoria._id} className = "helvetica tituloCategoriaMenu">{capitalizarPalabras(categoria["Categoria"+idioma])}</p>
                                            <img src = "/img/iconos/mas.png" className = "iconosMasMenu" alt = "Category"></img>
                                        </div>
                                    ))
                                }
                            </div>
                            :
                            <div className = "espacioCategoriasMenu">
                                <p className = "bebas tituloMenu" >{textos[idioma].Subcategorias + " - " + subcategoriasState["Categoria"+idioma]}</p>
                                {
                                    subcategoriasState.Subcategorias.map((subcategoria) => (
                                        <div className = "filaCategoriaMenu">
                                            <Link to={"/Productos/"+subcategoriasState.Categoria_id + "/" +subcategoria.Subcategoria._id} 
                                                  onClick = {() => obtenerProductos(subcategoriasState.Categoria_id, subcategoria.Subcategoria._id)} 
                                                  className = "helvetica tituloCategoriaMenu">
                                                    <p key = {subcategoria._id} className = "helvetica tituloCategoriaMenu">{capitalizarPalabras(subcategoria.Subcategoria["Subcategoria"+idioma])}</p>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </div>
                    }
                    
                </div>
            </MediaQuery>
        </header>
               
        
     );
}
 
export default Header;