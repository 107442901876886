import React , {useState, useContext, useEffect} from 'react';
import GeneralContext from '../../context/GeneralContext'
import Footer from '.././Footer/Footer'
import MediaQuery from 'react-responsive'
import { Link } from 'react-router-dom';
//Imports externos
import {
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    Modal, ModalHeader, ModalBody, ModalFooter
  } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

//Imports internos
import './static/css/Index.css'

const itemsWeb = [
{
    src: '/img/banner/banner1Web.png',
    altText: '',
    caption: ''
},
{
    src: '/img/banner/banner2Web.jpg',
    altText: '',
    caption: ''
},
{
    src: '/img/banner/banner3Web.jpg',
    altText: '',
    caption: ''
}
];
const itemsMovil = [
    {
        src: '/img/banner/banner1Movil.jpg',
        altText: '',
        caption: ''
    },
    {
        src: '/img/banner/banner2Movil.jpg',
        altText: '',
        caption: ''
    },
    {
        src: '/img/banner/banner3Movil.jpg',
        altText: '',
        caption: ''
    }
    ];
const Index = ({loadingState}) => {
    const GC = useContext(GeneralContext);
    const { barraBuscar, mostrarBarraBuscar, idioma, usuario, modificarUsuario } = GC;

    
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [modalInicio, setModalInicio] = useState((usuario.Departamento_Ciudad === "") || (usuario.Codigo_Postal === ""));
    const [textoError, setTextoError] = useState(false);
    const toggleModalInicio = () => setModalInicio(!modalInicio);

    

    const textos = {
        ES: {
            tituloCat: "Nuestras categorias",
            Envio: "Envios gratis",
            Categoria1: "ROPA DE HOMBRE",
            Categoria2: "GORRAS",
            Categoria3: "BOLSAS",
            Categoria4: "SEÑALIZACION",
            TituloModal: "Bienvenido a Mucho Mercho",
            TextoCuerpoModal: "Indicanos por favor tu localizacion para poder brindarte un mejor servicio",
            ProvinciaCiudad: "Provincia",
            ProvinciaCiudadPlaceholder: "Ingrese su provincia",
            CodigoPostal: "Codigo Postal",
            CodigoPostalSeleccion: "Ingrese su codigo postal",
            Aceptar: "Aceptar",
            Obligatorio: "Campos obligatorios",
        },
        EN: {
            tituloCat: "Our categories",
            Envio: "Free delivery",
            Categoria1: "MENS CLOTHING",
            Categoria2: "CAPS",
            Categoria3: "BAGS",
            Categoria4: "SIGNAGE",
            TituloModal: "Welcome to Mucho Mercho",
            TextoCuerpoModal: "Please indicate your location so that we can provide you with better service",
            ProvinciaCiudad: "Province",
            ProvinciaCiudadPlaceholder: "Enter your province",
            CodigoPostal: "Postal Code",
            CodigoPostalSeleccion: "Enter your zip code",
            Aceptar: "Accept",
            Obligatorio: "Required fields",
        }
    }

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === itemsWeb.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? itemsWeb.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slidesWeb = itemsWeb.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                <img  className = "imagenCarousel" src={item.src} alt={item.altText} />
                <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
            </CarouselItem>
        );
    });


    const slidesMovil = itemsMovil.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                <img  className = "imagenCarousel" src={item.src} alt={item.altText} />
                <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
            </CarouselItem>
        );
    });

    const validacion = (e) => {
        const { name, value } = e.target;
        if(name === "Departamento_Ciudad"){
            modificarUsuario({...usuario, [name] : value})
        }
        else{
            modificarUsuario({...usuario, [name] : value})
        }
    }

    const comprobarCampos = () => {
        if(usuario.Departamento_Ciudad === "" || usuario.Codigo_Postal === ""){
            setTextoError(true);
        }else{
            setTextoError(false);
            setModalInicio(false);
        }
    }


    useEffect(()=>{
        if(!barraBuscar)
            mostrarBarraBuscar(true);
    })

    return (
        <div className = "Index topIndex">
            <MediaQuery minWidth={700}>
                <div className = "carouselPersonalizado">
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                    >
                            <CarouselIndicators items={itemsWeb} activeIndex={activeIndex} onClickHandler={goToIndex} />
                            {slidesWeb}
                        <CarouselControl direction="prev" directionText=" " onClickHandler={previous} />
                        <CarouselControl direction="next" directionText=" " onClickHandler={next} />
                    </Carousel>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={699}>
                <div className = "carouselPersonalizado">
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                    >
                            <CarouselIndicators items={itemsMovil} activeIndex={activeIndex} onClickHandler={goToIndex} />
                            {slidesMovil}
                        <CarouselControl direction="prev" directionText=" " onClickHandler={previous} />
                        <CarouselControl direction="next" directionText=" " onClickHandler={next} />
                    </Carousel>
                </div>
            </MediaQuery>
            <div className = "filaTituloCategorias filaTituloCategoriasPosicion">
                <p className="tituloIndex bebas">{textos[idioma].tituloCat}</p>
                <MediaQuery minWidth={650}>
                    <div className = "EnviosGratis">
                        <img src = {"/img/iconos/envios.jpg"} className = "imagenEnvios" alt = "Delivery"></img>
                        <p className = "bebas tituloCategoriaIndex">{textos[idioma].Envio}</p>
                    </div>
                </MediaQuery>
            </div>
                
            <div className = "listaCategorias">
                <Link to = "/Productos/60b52a7941c7941a70223104/60b5199041cbdc2aac232e9e">
                    <div className = "espacioCategoria">
                        <img src = {"/img/categorias/60b52a7941c7941a70223104.jpg"} className = "imagenCategoria" alt = {textos[idioma].Categoria1}></img>
                        <p className = "bebas tituloCategoriaIndex">{textos[idioma].Categoria1}</p>
                    </div>
                </Link>
                <Link to = "/Productos/60b52a7a41c7941a70223120/60b5199041cbdc2aac232ea0">
                    <div className = "espacioCategoria">
                        <img src = {"/img/categorias/60b52a7a41c7941a70223120.jpg"} className = "imagenCategoria" alt = {textos[idioma].Categoria2}></img>
                        <p className = "bebas tituloCategoriaIndex">{textos[idioma].Categoria2}</p>
                    </div>
                </Link>
                <Link to = "/Productos/60b52a7a41c7941a7022311a/60b5199041cbdc2aac232e9f">
                    <div className = "espacioCategoria">
                        <img src = {"/img/categorias/60b52a7a41c7941a7022311a.jpg"} className = "imagenCategoria" alt = {textos[idioma].Categoria3}></img>
                        <p className = "bebas tituloCategoriaIndex">{textos[idioma].Categoria3}</p>
                    </div>
                </Link>
                <Link to = "/Productos/60b52a7a41c7941a7022312d/60b5199041cbdc2aac232ea3">
                    <div className = "espacioCategoria">
                        <img src = {"/img/categorias/60b52a7a41c7941a7022312d.jpg"} className = "imagenCategoria" alt = {textos[idioma].Categoria4}></img>
                        <p className = "bebas tituloCategoriaIndex">{textos[idioma].Categoria4}</p>
                    </div>
                </Link>
            </div>

            <Modal isOpen={modalInicio} toggle={toggleModalInicio} size = "lg">
                <ModalHeader className='modal-header-index'>
                    <label>
                        <p className = "titulo-header bebas">{textos[idioma].TituloModal}</p>
                    </label>
                </ModalHeader>
                <ModalBody>
                    <div className='cuerpo-header'>
                        <label>
                            <p className = "subtitulo-header helveticaBold">{textos[idioma].TextoCuerpoModal}</p>
                        </label>
                        <div className='disposicion-fila justify-around mt-5' style = {{width: "100%"}}>
                            <div className='disposicion-columna align-start'>
                                <label htmlFor="direccion" style = {{fontSize: "17px"}}>(*) {textos[idioma].ProvinciaCiudad}</label>
                                    <select className = "fontSelect"  name="Departamento_Ciudad" id="provincia_ciudad_input"  onChange = {(e) => validacion(e)} required>
                                        <option hidden defaultValue>{textos[idioma].ProvinciaCiudadPlaceholder}</option>
                                        <option value = "AB Alberta"> AB Alberta</option>
                                        <option value = "BC British Columbia"> BC British Columbia</option>
                                        <option value = "MB Manitoba"> MB Manitoba</option>
                                        <option value = "NB New Brunswick"> NB New Brunswick</option>
                                        <option value = "NF Newfoundland"> NF Newfoundland</option>
                                        <option value = "NS Nova Scotia"> NS Nova Scotia</option>
                                        <option value = "NU Nunavut"> NU Nunavut</option>
                                        <option value = "NT Northwest Territories"> NT Northwest Territories</option>
                                        <option value = "ON Ontario"> ON Ontario</option>
                                        <option value = "PE Prince Edward Island"> PE Prince Edward Island</option>
                                        <option value = "QC Quebec"> QC Quebec</option>
                                        <option value = "SK Saskatchewan"> SK Saskatchewan</option>
                                        <option value = "YT Yukon"> YT Yukon</option>
                                    </select>
                            </div>
                            <div className='disposicion-columna align-start'>
                                    <label htmlFor="codigoPostal" style = {{fontSize: "17px"}} className = "helveticaBold">(*) {textos[idioma].CodigoPostal}</label>
                                    <input  className = "input-codigo-postal" type="text" placeholder= {textos[idioma].CodigoPostalSeleccion} name="CodigoPostal" onChange = {(e) => validacion(e)} id="codigoPostal_input" required></input>
                            </div>
                        </div>
                        <label>
                            {textoError && <p className = "subtitulo-header helveticaBold" style = {{color: "red"}}>{textos[idioma].Obligatorio}</p>}
                        </label>
                        <Button color="secondary" className = "helveticaBold" onClick = {() => comprobarCampos()}>{textos[idioma].Aceptar}</Button>
                    </div>
                </ModalBody>
                <ModalFooter >
                    
                </ModalFooter>
            </Modal>
            <Footer/>
        </div>
  );
}
 
export default Index;